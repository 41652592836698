<div class="banner-section">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-7 col-md-12">
                <div class="banner-content">
                    <div class="content">
                        <div class="banner-content-slides owl-carousel owl-theme">
                            <div class="inner-content plr-15">
                                <h1>Web And Mobile App Development</h1>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
                                <a routerLink="/contact" class="default-btn"><i class="bx bxs-hot"></i> Contact Us<span></span></a>
                            </div>
                            <div class="inner-content plr-15">
                                <h1>Solve Business Challenges With IT</h1>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
                                <a routerLink="/contact" class="default-btn"><i class="bx bxs-hot"></i> Contact Us<span></span></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-5 col-md-12">
                <div class="banner-img banner-video">
                    <div class="d-table">
                        <div class="d-table-cell">
                            <div class="video-box">
                                <a href="https://www.youtube.com/watch?v=0gv7OC9L2s8" class="video-btn popup-youtube"><i class="bx bx-play"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="shape19"><img src="assets/img/shape/shape18.png" alt="image"></div>
    <div class="shape22"><img src="assets/img/shape/shape21.png" alt="image"></div>
    <div class="shape23"><img src="assets/img/shape/shape22.svg" alt="image"></div>
    <div class="shape24"><img src="assets/img/shape/shape23.png" alt="image"></div>
    <div class="shape25"><img src="assets/img/shape/shape24.png" alt="image"></div>
    <div class="shape26"><img src="assets/img/shape/shape25.png" alt="image"></div>
    <div class="shape20"><img src="assets/img/shape/shape19.png" alt="image"></div>
</div>

<div class="features-card-section pt-100 pb-70 bg-f8fbfa">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="single-features-card tx-center">
                    <i class='bx bx-conversation'></i>
                    <h3><a routerLink="/services-details">IT Consultancy</a></h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor dolore magna aliqua.</p>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="single-features-card tx-center">
                    <i class='bx bx-laptop'></i>
                    <h3><a routerLink="/services-details">Web Development</a></h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor dolore magna aliqua.</p>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="single-features-card tx-center">
                    <i class='bx bxs-badge-check'></i>
                    <h3><a routerLink="/services-details">IT Solutions</a></h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor dolore magna aliqua.</p>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="single-features-card tx-center">
                    <i class='bx bxs-megaphone'></i>
                    <h3><a routerLink="/services-details">Digital Marketing</a></h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor dolore magna aliqua.</p>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="services-area bg-right-shape ptb-100">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="services-content it-service-content">
                <div class="content left-content">
                    <div class="icon">
                        <img src="assets/img/icon1.png" alt="image">
                    </div>
                    <h2>IT Consultancy</h2>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris.</p>
                    <div class="row">
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="feature-box">
                                <i class='bx bxs-badge-check'></i>  
                                Responsive Design
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="feature-box">
                                <i class='bx bxs-badge-check'></i> 
                                UI / UX Design
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="feature-box">
                                <i class='bx bxs-badge-check'></i> 
                                Mobile App Development
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="feature-box">
                                <i class='bx bxs-badge-check'></i> 
                                Laravel Web Development
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="feature-box">
                                <i class='bx bxs-badge-check'></i> 
                                React Web Development
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="feature-box">
                                <i class='bx bxs-badge-check'></i> 
                                Angular Web Development
                            </div>
                        </div>
                    </div>
                    <a routerLink="/about" class="default-btn"><i class="bx bxs-spreadsheet"></i> Learn More <span></span></a>
                </div>
            </div>
            <div class="services-image">
                <div class="image">
                    <img src="assets/img/services-image/service5.png" alt="image">
                </div>
            </div>
        </div>
    </div>
</section>

<section class="services-area bg-left-color bg-left-shape bg-f4f6fc ptb-100">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="services-image">
                <div class="image">
                    <img src="assets/img/services-image/service2.png" alt="image">
                </div>
            </div>
            <div class="services-content it-service-content">
                <div class="content">
                    <div class="icon">
                        <img src="assets/img/icon1.png" alt="image">
                    </div>
                    <h2>Cloud Hosting Services</h2>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris.</p>
                    <div class="row">
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="feature-box">
                                <i class='bx bxs-badge-check'></i>  Cloud Databases
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="feature-box">
                                <i class='bx bxs-badge-check'></i> Hybrid Cloud
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="feature-box">
                                <i class='bx bxs-badge-check'></i> Email Servers
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="feature-box">
                                <i class='bx bxs-badge-check'></i> Website Hosting
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="feature-box">
                                <i class='bx bxs-badge-check'></i> File Storage
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="feature-box">
                                <i class='bx bxs-badge-check'></i> Backup Systems
                            </div>
                        </div>
                    </div>
                    <a routerLink="/about" class="default-btn"><i class="bx bxs-spreadsheet"></i>Learn More<span></span></a>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="team-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>Meet Our experts always ready to help you</h2>
        </div>
        <div class="row">
            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="single-team-box">
                    <div class="image">
                        <img src="assets/img/team-image/img1.jpg" alt="image">
                        <ul class="social">
                            <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-linkedin"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-instagram"></i></a></li>
                        </ul>
                    </div>
                    <div class="content">
                        <h3>Alex Maxwel</h3>
                        <span>CEO & Founder</span>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="single-team-box">
                    <div class="image">
                        <img src="assets/img/team-image/img2.jpg" alt="image">
                        <ul class="social">
                            <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-linkedin"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-instagram"></i></a></li>
                        </ul>
                    </div>
                    <div class="content">
                        <h3>Sarah Taylor</h3>
                        <span>UX/UI Designer</span>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="single-team-box">
                    <div class="image">
                        <img src="assets/img/team-image/img3.jpg" alt="image">
                        <ul class="social">
                            <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-linkedin"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-instagram"></i></a></li>
                        </ul>
                    </div>
                    <div class="content">
                        <h3>Lee Munroe</h3>
                        <span>Web Developer</span>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="single-team-box">
                    <div class="image">
                        <img src="assets/img/team-image/img4.jpg" alt="image">
                        <ul class="social">
                            <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-linkedin"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-instagram"></i></a></li>
                        </ul>
                    </div>
                    <div class="content">
                        <h3>Calvin Klein</h3>
                        <span>Support</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="features-area pt-100 pb-70 bg-f4f6fc">
    <div class="container">
        <div class="section-title">
            <h2>Our Amazing Features</h2>
        </div>
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-features-box">
                    <div class="icon">
                        <i class='bx bxs-badge-check'></i>
                    </div>
                    <h3>IT Solutions</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor dolore magna aliqua.</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-features-box">
                    <div class="icon">
                        <i class='bx bx-cog'></i>
                    </div>
                    <h3>Flexible Functionality</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor dolore magna aliqua.</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-features-box">
                    <div class="icon">
                        <i class='bx bx-conversation'></i>
                    </div>
                    <h3>IT Consultancy</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor dolore magna aliqua.</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-features-box">
                    <div class="icon">
                        <i class='bx bxs-dashboard'></i>
                    </div>
                    <h3>Simple Dashboard</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor dolore magna aliqua.</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-features-box">
                    <div class="icon">
                        <i class='bx bxs-info-circle'></i>
                    </div>
                    <h3>Information Retrieval</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor dolore magna aliqua.</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-features-box">
                    <div class="icon">
                        <i class='bx bxs-bell-ring'></i>
                    </div>
                    <h3>Deadline Reminders</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor dolore magna aliqua.</p>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="testimonials-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>Whats Our Clients Said About <span>Jexsa</span></h2>
        </div>
        <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-6">
                <div class="single-testimonials-item">
                    <div class="client-info">
                        <img src="assets/img/author-image/img6.jpg" alt="image">
                        <h3>Michel John</h3>
                        <span>CEO at Envato</span>
                    </div>
                    <div class="testimonials-desc">
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse.</p>
                        <div class="rating">
                            <i class="bx bxs-star"></i>
                            <i class="bx bxs-star"></i>
                            <i class="bx bxs-star"></i>
                            <i class="bx bxs-star"></i>
                            <i class="bx bxs-star"></i>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-6">
                <div class="single-testimonials-item">
                    <div class="client-info">
                        <img src="assets/img/author-image/img7.jpg" alt="image">
                        <h3>Sarah Taylor</h3>
                        <span>CEO at EnvyTheme</span>
                    </div>
                    <div class="testimonials-desc">
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse.</p>
                        <div class="rating">
                            <i class="bx bxs-star"></i>
                            <i class="bx bxs-star"></i>
                            <i class="bx bxs-star"></i>
                            <i class="bx bxs-star"></i>
                            <i class="bx bxs-star"></i>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-6">
                <div class="single-testimonials-item">
                    <div class="client-info">
                        <img src="assets/img/author-image/img8.jpg" alt="image">
                        <h3>James Andy</h3>
                        <span>CEO at 3S</span>
                    </div>
                    <div class="testimonials-desc">
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse.</p>
                        <div class="rating">
                            <i class="bx bxs-star"></i>
                            <i class="bx bxs-star"></i>
                            <i class="bx bxs-star"></i>
                            <i class="bx bxs-star"></i>
                            <i class="bx bxs-star"></i>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-6">
                <div class="single-testimonials-item">
                    <div class="client-info">
                        <img src="assets/img/author-image/img9.jpg" alt="image">
                        <h3>Jisan James</h3>
                        <span>CEO at IT4u</span>
                    </div>
                    <div class="testimonials-desc">
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse.</p>
                        <div class="rating">
                            <i class="bx bxs-star"></i>
                            <i class="bx bxs-star"></i>
                            <i class="bx bxs-star"></i>
                            <i class="bx bxs-star"></i>
                            <i class="bx bxs-star"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="pricing-area pt-100 pb-70 bg-f4f5fe">
    <div class="container">
        <div class="section-title">
            <h2>Choose The Pricing Plan</h2>
        </div>
        <div class="tab pricing-list-tab">
            <ul class="tabs">
                <li><a href="#"><i class="bx bxs-calendar-check"></i> Monthly</a></li>
                <li><a href="#"><i class="bx bxs-calendar-check"></i> Yearly</a></li>
            </ul>
            <div class="tab_content">
                <div class="tabs_item">
                    <div class="row">
                        <div class="col-lg-4 col-sm-6">
                            <div class="single-pricing-table left-align">
                                <div class="pricing-header">
                                    <h3>Free</h3>
                                </div>
                                <div class="price">
                                    <sup>$</sup>0 <sub>/ monthly</sub>
                                </div>
                                <ul class="pricing-features">
                                    <li><i class="bx bxs-badge-check"></i> Up to 3 chat operators <span class="tooltips bx bxs-info-circle" data-toggle="tooltip" data-placement="right" title="Tight pants next level keffiyeh you probably haven't heard of them."></span></li>

                                    <li><i class="bx bxs-badge-check"></i> 100 ChatBot Triggers</li>

                                    <li><i class="bx bxs-badge-check"></i> 24/7 Live Chat</li>

                                    <li><i class="bx bxs-badge-check"></i> Email Integration <span class="tooltips bx bxs-info-circle" data-toggle="tooltip" data-placement="right" title="Tight pants next level keffiyeh you probably haven't heard of them."></span></li>

                                    <li><i class="bx bxs-badge-check"></i> Messenger Integration</li>

                                    <li><i class="bx bxs-badge-check"></i> Visitor Info</li>

                                    <li><i class="bx bxs-badge-check"></i> Mobile + Desktop Apps</li>

                                    <li><i class="bx bxs-badge-check"></i> Quick Responses <span class="tooltips bx bxs-info-circle" data-toggle="tooltip" data-placement="right" title="Tight pants next level keffiyeh you probably haven't heard of them."></span></li>

                                    <li><i class="bx bxs-badge-check"></i> Drag & Drop Widgets</li>

                                    <li><i class="bx bxs-badge-check"></i> Visitor Notes <span class="tooltips bx bxs-info-circle" data-toggle="tooltip" data-placement="right" title="Tight pants next level keffiyeh you probably haven't heard of them."></span></li>

                                    <li><i class="bx bxs-badge-check"></i> Google Analytics</li>
                                </ul>
                                <div class="btn-box">
                                    <a href="#" class="default-btn"><i class="bx bxs-hot"></i> Try It Free Now<span></span></a>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-sm-6">
                            <div class="single-pricing-table left-align">
                                <div class="pricing-header">
                                    <h3>Starter</h3>
                                </div>
                                <div class="price">
                                    <sup>$</sup>49 <sub>/ monthly</sub>
                                </div>
                                <ul class="pricing-features">
                                    <li><i class="bx bxs-badge-check"></i> Up to 4 chat operators <span class="tooltips bx bxs-info-circle" data-toggle="tooltip" data-placement="right" title="Tight pants next level keffiyeh you probably haven't heard of them."></span></li>

                                    <li><i class="bx bxs-badge-check"></i> 150 ChatBot Triggers</li>

                                    <li><i class="bx bxs-badge-check"></i> 24/7 Live Chat</li>

                                    <li><i class="bx bxs-badge-check"></i> Email Integration <span class="tooltips bx bxs-info-circle" data-toggle="tooltip" data-placement="right" title="Tight pants next level keffiyeh you probably haven't heard of them."></span></li>

                                    <li><i class="bx bxs-badge-check"></i> Messenger Integration</li>

                                    <li><i class="bx bxs-badge-check"></i> Visitor Info</li>

                                    <li><i class="bx bxs-badge-check"></i> Mobile + Desktop Apps</li>

                                    <li><i class="bx bxs-badge-check"></i> Quick Responses <span class="tooltips bx bxs-info-circle" data-toggle="tooltip" data-placement="right" title="Tight pants next level keffiyeh you probably haven't heard of them."></span></li>

                                    <li><i class="bx bxs-badge-check"></i> Drag & Drop Widgets</li>

                                    <li><i class="bx bxs-badge-check"></i> Visitor Notes <span class="tooltips bx bxs-info-circle" data-toggle="tooltip" data-placement="right" title="Tight pants next level keffiyeh you probably haven't heard of them."></span></li>

                                    <li><i class="bx bxs-badge-check"></i> Google Analytics</li>
                                </ul>
                                <div class="btn-box">
                                    <a href="#" class="default-btn"><i class="bx bxs-hot"></i> Try It Free Now<span></span></a>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-sm-6 offset-lg-0 offset-sm-3">
                            <div class="single-pricing-table left-align">
                                <div class="pricing-header">
                                    <h3>Professional</h3>
                                </div>
                                <div class="price">
                                    <sup>$</sup>79 <sub>/ monthly</sub>
                                </div>
                                <ul class="pricing-features">
                                    <li><i class="bx bxs-badge-check"></i> Up to 7 chat operators <span class="tooltips bx bxs-info-circle" data-toggle="tooltip" data-placement="right" title="Tight pants next level keffiyeh you probably haven't heard of them."></span></li>

                                    <li><i class="bx bxs-badge-check"></i> 250 ChatBot Triggers</li>

                                    <li><i class="bx bxs-badge-check"></i> 24/7 Live Chat</li>

                                    <li><i class="bx bxs-badge-check"></i> Email Integration <span class="tooltips bx bxs-info-circle" data-toggle="tooltip" data-placement="right" title="Tight pants next level keffiyeh you probably haven't heard of them."></span></li>

                                    <li><i class="bx bxs-badge-check"></i> Messenger Integration</li>

                                    <li><i class="bx bxs-badge-check"></i> Visitor Info</li>

                                    <li><i class="bx bxs-badge-check"></i> Mobile + Desktop Apps</li>

                                    <li><i class="bx bxs-badge-check"></i> Quick Responses <span class="tooltips bx bxs-info-circle" data-toggle="tooltip" data-placement="right" title="Tight pants next level keffiyeh you probably haven't heard of them."></span></li>

                                    <li><i class="bx bxs-badge-check"></i> Drag & Drop Widgets</li>

                                    <li><i class="bx bxs-badge-check"></i> Visitor Notes <span class="tooltips bx bxs-info-circle" data-toggle="tooltip" data-placement="right" title="Tight pants next level keffiyeh you probably haven't heard of them."></span></li>

                                    <li><i class="bx bxs-badge-check"></i> Google Analytics</li>
                                </ul>
                                <div class="btn-box">
                                    <a href="#" class="default-btn"><i class="bx bxs-hot"></i> Try It Free Now<span></span></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tabs_item">
                    <div class="row">
                        <div class="col-lg-4 col-md-6">
                            <div class="single-pricing-table left-align">
                                <div class="pricing-header">
                                    <h3>Free</h3>
                                </div>
                                <div class="price">
                                    <sup>$</sup>0 <sub>/ yearly</sub>
                                </div>
                                <ul class="pricing-features">
                                    <li><i class="bx bxs-badge-check"></i> Up to 3 chat operators <span class="tooltips bx bxs-info-circle" data-toggle="tooltip" data-placement="right" title="Tight pants next level keffiyeh you probably haven't heard of them."></span></li>

                                    <li><i class="bx bxs-badge-check"></i> 100 ChatBot Triggers</li>

                                    <li><i class="bx bxs-badge-check"></i> 24/7 Live Chat</li>

                                    <li><i class="bx bxs-badge-check"></i> Email Integration <span class="tooltips bx bxs-info-circle" data-toggle="tooltip" data-placement="right" title="Tight pants next level keffiyeh you probably haven't heard of them."></span></li>

                                    <li><i class="bx bxs-badge-check"></i> Messenger Integration</li>

                                    <li><i class="bx bxs-badge-check"></i> Visitor Info</li>

                                    <li><i class="bx bxs-badge-check"></i> Mobile + Desktop Apps</li>

                                    <li><i class="bx bxs-badge-check"></i> Quick Responses <span class="tooltips bx bxs-info-circle" data-toggle="tooltip" data-placement="right" title="Tight pants next level keffiyeh you probably haven't heard of them."></span></li>

                                    <li><i class="bx bxs-badge-check"></i> Drag & Drop Widgets</li>

                                    <li><i class="bx bxs-badge-check"></i> Visitor Notes <span class="tooltips bx bxs-info-circle" data-toggle="tooltip" data-placement="right" title="Tight pants next level keffiyeh you probably haven't heard of them."></span></li>

                                    <li><i class="bx bxs-badge-check"></i> Google Analytics</li>
                                </ul>
                                <div class="btn-box">
                                    <a href="#" class="default-btn"><i class="bx bxs-hot"></i> Try It Free Now<span></span></a>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6">
                            <div class="single-pricing-table left-align">
                                <div class="pricing-header">
                                    <h3>Starter</h3>
                                </div>
                                <div class="price">
                                    <sup>$</sup>79 <sub>/ yearly</sub>
                                </div>
                                <ul class="pricing-features">
                                    <li><i class="bx bxs-badge-check"></i> Up to 4 chat operators <span class="tooltips bx bxs-info-circle" data-toggle="tooltip" data-placement="right" title="Tight pants next level keffiyeh you probably haven't heard of them."></span></li>

                                    <li><i class="bx bxs-badge-check"></i> 150 ChatBot Triggers</li>

                                    <li><i class="bx bxs-badge-check"></i> 24/7 Live Chat</li>

                                    <li><i class="bx bxs-badge-check"></i> Email Integration <span class="tooltips bx bxs-info-circle" data-toggle="tooltip" data-placement="right" title="Tight pants next level keffiyeh you probably haven't heard of them."></span></li>

                                    <li><i class="bx bxs-badge-check"></i> Messenger Integration</li>

                                    <li><i class="bx bxs-badge-check"></i> Visitor Info</li>

                                    <li><i class="bx bxs-badge-check"></i> Mobile + Desktop Apps</li>

                                    <li><i class="bx bxs-badge-check"></i> Quick Responses <span class="tooltips bx bxs-info-circle" data-toggle="tooltip" data-placement="right" title="Tight pants next level keffiyeh you probably haven't heard of them."></span></li>

                                    <li><i class="bx bxs-badge-check"></i> Drag & Drop Widgets</li>

                                    <li><i class="bx bxs-badge-check"></i> Visitor Notes <span class="tooltips bx bxs-info-circle" data-toggle="tooltip" data-placement="right" title="Tight pants next level keffiyeh you probably haven't heard of them."></span></li>

                                    <li><i class="bx bxs-badge-check"></i> Google Analytics</li>
                                </ul>
                                <div class="btn-box">
                                    <a href="#" class="default-btn"><i class="bx bxs-hot"></i> Try It Free Now<span></span></a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 offset-lg-0 offset-md-3">
                            <div class="single-pricing-table left-align">
                                <div class="pricing-header">
                                    <h3>Professional</h3>
                                </div>
                                <div class="price">
                                    <sup>$</sup>99 <sub>/ yearly</sub>
                                </div>
                                <ul class="pricing-features">
                                    <li><i class="bx bxs-badge-check"></i> Up to 7 chat operators <span class="tooltips bx bxs-info-circle" data-toggle="tooltip" data-placement="right" title="Tight pants next level keffiyeh you probably haven't heard of them."></span></li>

                                    <li><i class="bx bxs-badge-check"></i> 250 ChatBot Triggers</li>

                                    <li><i class="bx bxs-badge-check"></i> 24/7 Live Chat</li>

                                    <li><i class="bx bxs-badge-check"></i> Email Integration <span class="tooltips bx bxs-info-circle" data-toggle="tooltip" data-placement="right" title="Tight pants next level keffiyeh you probably haven't heard of them."></span></li>

                                    <li><i class="bx bxs-badge-check"></i> Messenger Integration</li>

                                    <li><i class="bx bxs-badge-check"></i> Visitor Info</li>

                                    <li><i class="bx bxs-badge-check"></i> Mobile + Desktop Apps</li>

                                    <li><i class="bx bxs-badge-check"></i> Quick Responses <span class="tooltips bx bxs-info-circle" data-toggle="tooltip" data-placement="right" title="Tight pants next level keffiyeh you probably haven't heard of them."></span></li>

                                    <li><i class="bx bxs-badge-check"></i> Drag & Drop Widgets</li>

                                    <li><i class="bx bxs-badge-check"></i> Visitor Notes <span class="tooltips bx bxs-info-circle" data-toggle="tooltip" data-placement="right" title="Tight pants next level keffiyeh you probably haven't heard of them."></span></li>

                                    <li><i class="bx bxs-badge-check"></i> Google Analytics</li>
                                </ul>
                                <div class="btn-box">
                                    <a href="#" class="default-btn"><i class="bx bxs-hot"></i> Try It Free Now<span></span></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="our-loving-clients ptb-100 bg-color-f8fbfa">
    <div class="container">
        <div class="section-title">
            <h2>Our Loving Clients</h2>
        </div>
        <div class="clients-logo-list align-items-center">
            <div class="single-clients-logo">
                <a href="#">
                    <img src="assets/img/clients-image/img1.png" alt="image">
                </a>
            </div>
            <div class="single-clients-logo">
                <a href="#">
                    <img src="assets/img/clients-image/img2.png" alt="image">
                </a>
            </div>
            <div class="single-clients-logo">
                <a href="#">
                    <img src="assets/img/clients-image/img3.png" alt="image">
                </a>
            </div>
            <div class="single-clients-logo">
                <a href="#">
                    <img src="assets/img/clients-image/img4.png" alt="image">
                </a>
            </div>
            <div class="single-clients-logo">
                <a href="#">
                    <img src="assets/img/clients-image/img5.png" alt="image">
                </a>
            </div>
            <div class="single-clients-logo">
                <a href="#">
                    <img src="assets/img/clients-image/img6.png" alt="image">
                </a>
            </div>
            <div class="single-clients-logo">
                <a href="#">
                    <img src="assets/img/clients-image/img7.png" alt="image">
                </a>
            </div>
            <div class="single-clients-logo">
                <a href="#">
                    <img src="assets/img/clients-image/img8.png" alt="image">
                </a>
            </div>
            <div class="single-clients-logo">
                <a href="#">
                    <img src="assets/img/clients-image/img9.png" alt="image">
                </a>
            </div>
        </div>
    </div>
</section>

<section class="app-download-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-5 col-md-12">
                <div class="app-download-image">
                    <img src="assets/img/mobile.png" alt="image">
                </div>
            </div>
            <div class="col-lg-7 col-md-12">
                <div class="app-download-content">
                    <span class="sub-title">Download App</span>
                    <h2>Supporting your customers on the go with our mobile app</h2>
                    <div class="btn-box">
                        <a href="#" class="apple-store-btn"><img src="assets/img/applestore.png" alt="image">Download on the<span>Apple Store</span></a>
                        <a href="#" class="play-store-btn"><img src="assets/img/playstore.png" alt="image">Get it on<span>Google Play</span></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="blog-area ptb-100">
    <div class="container">
        <div class="section-title">
            <h2>Our Latest News</h2>
        </div>
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <div class="post-image">
                        <a routerLink="/blog-details"><img src="assets/img/blog-image/img1.jpg" alt="image"></a>
                        <div class="date"><i class='bx bx-calendar'></i> Oct 14, 2020</div>
                    </div>
                    <div class="post-content">
                        <h3><a routerLink="/blog-details">50 world-changing people, We lost in the 2010s</a></h3>
                        <div class="post-info">
                            <div class="post-by">
                                <img src="assets/img/author-image/img1.jpg" alt="image">
                                <h6>Sarah Taylor</h6>
                            </div>
                            <div class="details-btn">
                                <a routerLink="/blog-details"><i class="bx bx-right-arrow-alt"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <div class="post-image">
                        <a routerLink="/blog-details"><img src="assets/img/blog-image/img2.jpg" alt="image"></a>
                        <div class="date"><i class='bx bx-calendar'></i> Oct 16, 2020</div>
                    </div>
                    <div class="post-content">
                        <h3><a routerLink="/blog-details">Don't buy a tech gift until you read these rules</a></h3>
                        <div class="post-info">
                            <div class="post-by">
                                <img src="assets/img/author-image/img2.jpg" alt="image">
                                <h6>Michel John</h6>
                            </div>
                            <div class="details-btn">
                                <a routerLink="/blog-details"><i class="bx bx-right-arrow-alt"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 offset-lg-0 offset-md-3">
                <div class="single-blog-post">
                    <div class="post-image">
                        <a routerLink="/blog-details"><img src="assets/img/blog-image/img3.jpg" alt="image"></a>
                        <div class="date"><i class='bx bx-calendar'></i> Oct 18, 2020</div>
                    </div>
                    <div class="post-content">
                        <h3><a routerLink="/blog-details">The golden rule of buying a phone as a gift</a></h3>
                        <div class="post-info">
                            <div class="post-by">
                                <img src="assets/img/author-image/img3.jpg" alt="image">
                                <h6>Lucy Eva</h6>
                            </div>
                            <div class="details-btn">
                                <a routerLink="/blog-details"><i class="bx bx-right-arrow-alt"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-12 col-md-12">
                <div class="blog-notes">
                    <p>Insights to help you do what you do better, faster and more profitably. <a routerLink="/blog-1">Read Full Blog</a></p>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="subscribe-area bg-f4f5fe">
    <div class="container">
        <div class="subscribe-content">
            <h2>We always try to be the best support to you as possible</h2>
            <form class="newsletter-form">
                <div class="row align-items-center">
                    <div class="col-lg-8 col-md-8">
                        <input type="email" class="input-newsletter" placeholder="hello@jexsa.com" name="EMAIL" required>
                    </div>
                    <div class="col-lg-4 col-md-4">
                        <button type="submit"><i class="bx bxs-hot"></i> Subscribe Now</button>
                    </div>
                </div>
            </form>
            <div class="shape14"><img src="assets/img/shape/shape13.png" alt="image"></div>
            <div class="shape15"><img src="assets/img/shape/shape14.png" alt="image"></div>
            <div class="shape16"><img src="assets/img/shape/shape15.png" alt="image"></div>
            <div class="shape17"><img src="assets/img/shape/shape16.png" alt="image"></div>
            <div class="shape18"><img src="assets/img/shape/shape17.png" alt="image"></div>
        </div>
    </div>
</section>

<section class="free-trial-area ptb-100 bg-f4f5fe">
    <div class="container">
        <div class="free-trial-content">
            <h2>We always try to be the best support to you as possible</h2>
            <p>Qualify your leads & recognize the value of word your customer will love you</p>
            <a routerLink="/contact" class="default-btn"><i class="bx bxs-hot"></i> Contact Us<span></span></a>
        </div>
    </div>
    <div class="shape10"><img src="assets/img/shape/shape10.png" alt="image"></div>
    <div class="shape11"><img src="assets/img/shape/shape7.png" alt="image"></div>
    <div class="shape12"><img src="assets/img/shape/shape11.png" alt="image"></div>
    <div class="shape13"><img src="assets/img/shape/shape12.png" alt="image"></div>
</section>