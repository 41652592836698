<div class="container">
  <div class="content">
    <h2 class="header">Privacy Policy</h2>
    <h2 class="subtitle">Your privacy is critically important to us</h2>
    <p class="location-title">B Space is located at:</p>
    <p class="location">B Space</p>
    <p class="location">8901 Woodbine Ave 200, Markham</p>
    <p class="location">L3R9Y4 - Ontario , Canada</p>
    <p class="paragraph">
      It is B Space's policy to respect your privacy regarding any information
      we may collect while operating our website. This Privacy Policy applies to
      <a href="https://bspace.ca">https://bspace.ca</a>(hereinafter, "us", "we",
      or "https://bspace.ca"). We respect your privacy and are committed to
      protecting personally identifiable information you may provide us through
      the Website. We have adopted this privacy policy ("Privacy Policy") to
      explain what information may be collected on our Website, how we use this
      information, and under what circumstances we may disclose the information
      to third parties. This Privacy Policy applies only to information we
      collect through the Website and does not apply to our collection of
      information from other sources.
    </p>
    <p class="paragraph">
      This Privacy Policy, together with the Terms and conditions posted on our
      Website, set forth the general rules and policies governing your use of
      our Website. Depending on your activities when visiting our Website, you
      may be required to agree to additional terms and conditions.
    </p>
    <h2 class="subtitle">Gathering of Personally-Identifying Information</h2>
    <p class="paragraph">
      Certain visitors to B Space's websites choose to interact with B Space in
      ways that require B Space to gather personally-identifying information.
      The amount and type of information that B Space gathers depends on the
      nature of the interaction. For example, we ask visitors who sign up for a
      blog at https://bspace.ca to provide a username and email address.
    </p>
    <h2 class="subtitle">Security</h2>
    <p class="paragraph">
      The security of your Personal Information is important to us, but remember
      that no method of transmission over the Internet, or method of electronic
      storage is 100% secure. While we strive to use commercially acceptable
      means to protect your Personal Information, we cannot guarantee its
      absolute security.
    </p>
    <h2 class="subtitle">
      Protection of Certain Personally-Identifying Information
    </h2>
    <p class="paragraph">
      B Space discloses potentially personally-identifying and
      personally-identifying information only to those of its employees,
      contractors and affiliated organizations that (i) need to know that
      information in order to process it on B Space's behalf or to provide
      services available at B Space's website, and (ii) that have agreed not to
      disclose it to others. Some of those employees, contractors and affiliated
      organizations may be located outside of your home country; by using B
      Space's website, you consent to the transfer of such information to them.
      B Space will not rent or sell potentially personally-identifying and
      personally-identifying information to anyone. Other than to its employees,
      contractors and affiliated organizations, as described above, B Space
      discloses potentially personally-identifying and personally-identifying
      information only in response to a subpoena, court order or other
      governmental request, or when B Space believes in good faith that
      disclosure is reasonably necessary to protect the property or rights of B
      Space, third parties or the public at large.
    </p>
    <p class="paragraph">
      If you are a registered user of https://bspace.ca and have supplied your
      email address, B Space may occasionally send you an email to tell you
      about new features, solicit your feedback, or just keep you up to date
      with what's going on with B Space and our products. We primarily use our
      blog to communicate this type of information, so we expect to keep this
      type of email to a minimum. If you send us a request (for example via a
      support email or via one of our feedback mechanisms), we reserve the right
      to publish it in order to help us clarify or respond to your request or to
      help us support other users. B Space takes all measures reasonably
      necessary to protect against the unauthorized access, use, alteration or
      destruction of potentially personally-identifying and
      personally-identifying information.
    </p>
    <h2 class="subtitle">Privacy Policy Changes</h2>
    <p class="paragraph">
      Although most changes are likely to be minor, B Space may change its
      Privacy Policy from time to time, and in B Space's sole discretion. B
      Space encourages visitors to frequently check this page for any changes to
      its Privacy Policy. Your continued use of this site after any change in
      this Privacy Policy will constitute your acceptance of such change.
    </p>
    <h2 class="subtitle">Contact Information</h2>
    <p class="paragraph">
      If you have any questions about this Privacy Policy, please contact us via
      <a href="mailto:info@bspace.ca?Subject=Hello%20B-Space" target="_top"
        >info@bspace.ca</a
      >.
    </p>
  </div>
</div>
