<section class="signup-area">
    <div class="row m-0">
        <div class="col-lg-6 col-md-12 p-0">
            <div class="signup-image">
                <img src="assets/img/signup-bg.jpg" alt="image">
            </div>
        </div>
        <div class="col-lg-6 col-md-12 p-0">
            <div class="signup-content">
                <div class="d-table">
                    <div class="d-table-cell">
                        <div class="signup-form">
                            <h3>Create an account</h3>
                            <p>Already signed up? <a routerLink="/log-in">Log in</a></p>
                            <form>
                                <div class="form-group">
                                    <input type="text" name="username" id="username" placeholder="Your Username" class="form-control">
                                </div>
                                <div class="form-group">
                                    <input type="email" name="email" id="email" placeholder="Your email address" class="form-control">
                                </div>
                                <div class="form-group">
                                    <input type="password" name="password" id="password" placeholder="Create a password" class="form-control">
                                </div>
                                <div class="form-group">
                                    <input type="password" name="confirm-password" id="confirm-password" placeholder="Confirm Password" class="form-control">
                                </div>
                                <button type="submit" class="default-btn"><i class="bx bx-log-in"></i>Sign Up<span></span></button>
                                <div class="connect-with-social">
                                    <span>Or</span>
                                    <button type="submit" class="facebook"><i class='bx bxl-facebook'></i> Connect with Facebook</button>
                                    <button type="submit" class="google"><i class='bx bxl-google'></i> Connect with Google</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>