<section class="contact-area">
  <div class="">
    <div class="contact-inner">
      <div class="row">
        <div
          class="col-lg-6 col-md-12"
          style="
            background-image: url('assets/img/contact-section/contact-1.jpeg');
            background-size: cover;
            background-position: center center;
          "
        >
          <!-- <div class="contact-features-list">
              <h3>Some other great reasons to choose us</h3>
              <p>
                Take the first steps towards increased productivity and reduced
                stress with Strax.
              </p>
              <p>
                We are a highly trained, dedicated team, helping entrepreneurs,
                professionals, and small teams streamline success - not just in
                work, but in life too. We help spare your time to focus on the
                bigger picture by taking care of the little details.
              </p>
              <ul>
                <li>
                  <i class="bx bx-badge-check"></i> Staff works from our offices
                  in USA
                </li>
                <li>
                  <i class="bx bx-badge-check"></i> Scale up/ down on demand
                </li>
                <li>
                  <i class="bx bx-badge-check"></i> One time or on going projects
                </li>
                <li><i class="bx bx-badge-check"></i> Hire based on skill set</li>
              </ul>
            </div> -->
        </div>
        <div class="col-lg-6 col-md-12">
          <div class="contact-form">
            <h3>Let's Start Your Free Trial</h3>
            <form id="contactForm">
              <div class="row">
                <div class="col-lg-6 col-md-6">
                  <div class="form-group">
                    <input
                      type="text"
                      name="fist"
                      [(ngModel)]="contactForm.first"
                      id="fist"
                      class="form-control"
                      required
                      placeholder="First Name"
                    />
                  </div>
                </div>
                <div class="col-lg-6 col-md-6">
                  <div class="form-group">
                    <input
                      type="text"
                      name="last"
                      [(ngModel)]="contactForm.last"
                      id="last"
                      class="form-control"
                      required
                      placeholder="Last Name"
                    />
                  </div>
                </div>
                <div class="col-lg-6 col-md-6">
                  <div class="form-group">
                    <input
                      type="email"
                      name="email"
                      [(ngModel)]="contactForm.email"
                      id="email"
                      class="form-control"
                      required
                      placeholder="Your Email"
                    />
                  </div>
                </div>
                <div class="col-lg-6 col-md-6">
                  <div class="form-group">
                    <input
                      type="text"
                      name="tel"
                      [(ngModel)]="contactForm.tel"
                      id="tel"
                      class="form-control"
                      required
                      placeholder="Your Phone Number"
                    />
                  </div>
                </div>
                <div class="col-lg-12 col-md-12">
                  <div class="form-group">
                    <input
                      type="text"
                      name="company"
                      [(ngModel)]="contactForm.company"
                      id="company"
                      class="form-control"
                      required
                      placeholder="Your Company Name"
                    />
                  </div>
                </div>
                <div class="col-lg-12 col-md-12">
                  <div class="form-group">
                    <textarea
                      name="description"
                      class="form-control"
                      id="description"
                      [(ngModel)]="contactForm.description"
                      cols="30"
                      rows="6"
                      required
                      placeholder="Your Message"
                    ></textarea>
                  </div>
                </div>

                <p *ngIf="error | async" class="col-md-12 fg-red">
                  Request can not be completed, please try again later.
                </p>

                <p *ngIf="success | async" class="col-md-12 fg-success">
                  Your request has been submitted successfully, our customer
                  service will contact will shortly.
                </p>

                <div class="col-lg-12 col-md-12">
                  <button
                    *ngIf="!(submitted | async)"
                    type="submit"
                    class="default-btn"
                    [disabled]="loading | async"
                    (click)="submitForm()"
                    [class]="{
                      disabled: loading | async
                    }"
                  >
                    <!-- <i class="bx bxs-paper-plane"></i> -->
                    Join as a Pro<span></span>
                  </button>
                </div>
                <div class="contact-info">
                  <div class="contact-info-content">
                    <h3>Contact us by Email Address</h3>
                    <!-- <div>
                        <a href="tel:+0881306298615">+088 130 629 8615</a>
                        <span>OR</span>
                        <a href="mailto:hello@jexsa.com">hello@jexsa.com</a>
                      </div> -->
                    <address class="middle">
                      <div>Monday to Friday</div>
                      <div>9:30 AM - 6:00 PM EST</div>
                      <div>6:30 AM - 3:00 PM PST</div>
                      <br />
                      <!-- <div>
                        <a href="tel:1(905)-111-1111">1(905)-111-1111</a>
                      </div> -->
                      <div>
                        <a
                          href="mailto:info@bspace.ca?Subject=Hello%20B-Space"
                          target="_top"
                          >info@bspace.ca</a
                        >
                      </div>
                    </address>

                    <!-- <ul class="social">
                        <li>
                          <a href="#" target="_blank"
                            ><i class="bx bxl-twitter"></i
                          ></a>
                        </li>
                        <li>
                          <a href="#" target="_blank"
                            ><i class="bx bxl-youtube"></i
                          ></a>
                        </li>
                        <li>
                          <a href="#" target="_blank"
                            ><i class="bx bxl-facebook"></i
                          ></a>
                        </li>
                        <li>
                          <a href="#" target="_blank"
                            ><i class="bx bxl-linkedin"></i
                          ></a>
                        </li>
                        <li>
                          <a href="#" target="_blank"
                            ><i class="bx bxl-instagram"></i
                          ></a>
                        </li>
                      </ul> -->
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
