<div class="main-banner main-banner-one">
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-7 col-md-12">
        <div class="main-banner-content">
          <div class="d-table">
            <div class="d-table-cell">
              <div class="content">
                <h1>
                  Manage your business with one application
                </h1>
                <p>
                  Join as a Pro user with B Space will make managing your
                  business easier. Look at our many features below!
                </p>
                <a routerLink="/contact" class="default-btn">
                  <!-- <i class="bx bxs-hot"></i> -->
                  Join as a Pro
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-5 col-md-12">
        <div class="banner-image mbanner-bg-one">
          <div class="d-table">
            <div class="d-table-cell">
              <div class="animate-banner-image">
                <img src="assets/img/animate-banner-img1.jpg" alt="image" />
              </div>
            </div>
          </div>
          <img
            src="assets/img/banner-slider/banner-img1.jpg"
            class="mbanner-img"
            alt="image"
          />
        </div>
      </div>
    </div>
  </div>
  <div class="shape20">
    <img src="assets/img/shape/shape19.png" alt="image" />
  </div>
  <div class="shape21">
    <img src="assets/img/shape/shape20.png" alt="image" />
  </div>
  <div class="shape19">
    <img src="assets/img/shape/shape18.png" alt="image" />
  </div>
  <div class="shape22">
    <img src="assets/img/shape/shape21.png" alt="image" />
  </div>
  <div class="shape23">
    <img src="assets/img/shape/shape22.svg" alt="image" />
  </div>
  <div class="shape24">
    <img src="assets/img/shape/shape23.png" alt="image" />
  </div>
  <div class="shape26">
    <img src="assets/img/shape/shape25.png" alt="image" />
  </div>
</div>

<div class="features-card-section pt-100 pb-70 bg-f8fbfa">
  <div class="container">
    <div class="row">
      <div class="col-lg-3 col-sm-6 col-md-6 wow fadeInUp" data-wow-delay=".2s">
        <div class="single-features-card tx-center">
          <i class="bx bx-conversation"></i>
          <h3>
            <a routerLink="/services-details">IT Consultancy</a>
          </h3>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor dolore magna aliqua.
          </p>
        </div>
      </div>
      <div class="col-lg-3 col-sm-6 col-md-6 wow fadeInUp" data-wow-delay=".4s">
        <div class="single-features-card tx-center">
          <i class="bx bx-laptop"></i>
          <h3>
            <a routerLink="/services-details">Web Development</a>
          </h3>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor dolore magna aliqua.
          </p>
        </div>
      </div>
      <div class="col-lg-3 col-sm-6 col-md-6 wow fadeInUp" data-wow-delay=".6s">
        <div class="single-features-card tx-center">
          <i class="bx bxs-badge-check"></i>
          <h3><a routerLink="/services-details">IT Solutions</a></h3>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor dolore magna aliqua.
          </p>
        </div>
      </div>
      <div class="col-lg-3 col-sm-6 col-md-6 wow fadeInUp" data-wow-delay=".8s">
        <div class="single-features-card tx-center">
          <i class="bx bxs-megaphone"></i>
          <h3>
            <a routerLink="/services-details">Digital Marketing</a>
          </h3>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor dolore magna aliqua.
          </p>
        </div>
      </div>
    </div>
  </div>
</div>

<section class="services-area bg-right-shape ptb-100">
  <div class="container-fluid">
    <div class="row align-items-center">
      <div class="services-content it-service-content">
        <div class="content left-content">
          <div class="icon">
            <img src="assets/img/icon1.png" alt="image" />
          </div>
          <h2>Web & Mobile Development</h2>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris.
          </p>
          <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-6">
              <div class="feature-box">
                <i class="bx bxs-badge-check"></i> Responsive Design
              </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-6">
              <div class="feature-box">
                <i class="bx bxs-badge-check"></i> UI / UX Design
              </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-6">
              <div class="feature-box">
                <i class="bx bxs-badge-check"></i> Mobile App Development
              </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-6">
              <div class="feature-box">
                <i class="bx bxs-badge-check"></i> Laravel Web Development
              </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-6">
              <div class="feature-box">
                <i class="bx bxs-badge-check"></i> React Web Development
              </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-6">
              <div class="feature-box">
                <i class="bx bxs-badge-check"></i> Angular Web Development
              </div>
            </div>
          </div>
          <a routerLink="/about" class="default-btn"
            ><i class="bx bxs-spreadsheet"></i> Learn More<span></span
          ></a>
        </div>
      </div>
      <div class="services-image wow fadeInRight" data-wow-delay=".3s">
        <div class="image">
          <img src="assets/img/services-image/service1.png" alt="image" />
        </div>
      </div>
    </div>
  </div>
</section>

<section class="services-area bg-left-color bg-left-shape bg-f4f6fc ptb-100">
  <div class="container-fluid">
    <div class="row align-items-center">
      <div class="services-image wow fadeInLeft" data-wow-delay=".3s">
        <div class="image">
          <img src="assets/img/services-image/service2.png" alt="image" />
        </div>
      </div>
      <div class="services-content it-service-content">
        <div class="content">
          <div class="icon">
            <img src="assets/img/icon1.png" alt="image" />
          </div>
          <h2>Cloud Hosting Services</h2>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris.
          </p>
          <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-6">
              <div class="feature-box">
                <i class="bx bxs-badge-check"></i> Cloud Databases
              </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-6">
              <div class="feature-box">
                <i class="bx bxs-badge-check"></i> Hybrid Cloud
              </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-6">
              <div class="feature-box">
                <i class="bx bxs-badge-check"></i> Email Servers
              </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-6">
              <div class="feature-box">
                <i class="bx bxs-badge-check"></i> Website Hosting
              </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-6">
              <div class="feature-box">
                <i class="bx bxs-badge-check"></i> File Storage
              </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-6">
              <div class="feature-box">
                <i class="bx bxs-badge-check"></i> Backup Systems
              </div>
            </div>
          </div>
          <a routerLink="/about" class="default-btn"
            ><i class="bx bxs-spreadsheet"></i>Learn More<span></span
          ></a>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="video-presentation-area ptb-100">
  <div class="container">
    <div class="section-title">
      <h2>Why choose us to watch this video know more</h2>
    </div>
    <div class="video-box">
      <img src="assets/img/video-bg.jpg" class="main-image" alt="image" />
      <a
        href="https://www.youtube.com/watch?v=0gv7OC9L2s8"
        class="video-btn popup-youtube"
        ><i class="bx bx-play"></i
      ></a>
      <div class="shape1">
        <img src="assets/img/shape/shape1.png" alt="image" />
      </div>
      <div class="shape2">
        <img src="assets/img/shape/shape2.png" alt="image" />
      </div>
      <div class="shape3">
        <img src="assets/img/shape/shape3.png" alt="image" />
      </div>
      <div class="shape4">
        <img src="assets/img/shape/shape4.png" alt="image" />
      </div>
      <div class="shape5">
        <img src="assets/img/shape/shape5.png" alt="image" />
      </div>
      <div class="shape6">
        <img src="assets/img/shape/shape6.png" alt="image" />
      </div>
    </div>
    <div class="funfacts-inner">
      <div class="row">
        <div class="col-lg-3 col-6 col-sm-6">
          <div
            class="single-funfacts funfact-style-two wow fadeInLeft"
            data-wow-delay=".2s"
          >
            <i class="bx bx-list-check"></i>
            <h3>
              <span class="odometer" data-count="900">00</span
              ><span class="sign-icon">+</span>
            </h3>
            <p>Completed Projects</p>
          </div>
        </div>
        <div class="col-lg-3 col-6 col-sm-6">
          <div
            class="single-funfacts funfact-style-two wow fadeInLeft"
            data-wow-delay=".4s"
          >
            <i class="bx bx-smile"></i>
            <h3>
              <span class="odometer" data-count="850">00</span
              ><span class="sign-icon">+</span>
            </h3>
            <p>Happy Clients</p>
          </div>
        </div>
        <div class="col-lg-3 col-6 col-sm-6">
          <div
            class="single-funfacts funfact-style-two wow fadeInLeft"
            data-wow-delay=".6s"
          >
            <i class="bx bx-grid-small"></i>
            <h3>
              <span class="odometer" data-count="120">00</span
              ><span class="sign-icon">+</span>
            </h3>
            <p>Ongoing Projects</p>
          </div>
        </div>
        <div
          class="col-lg-3 col-6 col-sm-6 wow fadeInLeft"
          data-wow-delay=".8s"
        >
          <div class="single-funfacts funfact-style-two">
            <i class="bx bxs-award"></i>
            <h3>
              <span class="odometer" data-count="50">00</span
              ><span class="sign-icon">+</span>
            </h3>
            <p>Winning Awards</p>
          </div>
        </div>
      </div>
    </div>
    <div class="contact-cta-box mwidth-1000 wow fadeInUp" data-wow-delay="1s">
      <h3>Have any question about us?</h3>
      <p>Don't hesitate to contact us.</p>
      <a routerLink="/contact" class="default-btn"
        ><i class="bx bxs-edit-alt"></i> Contact Us<span></span
      ></a>
    </div>
  </div>
  <div class="shape-map1"><img src="assets/img/map1.png" alt="image" /></div>
  <div class="shape7">
    <img src="assets/img/shape/shape7.png" alt="image" />
  </div>
  <div class="shape8">
    <img src="assets/img/shape/shape8.png" alt="image" />
  </div>
  <div class="shape9">
    <img src="assets/img/shape/shape9.png" alt="image" />
  </div>
</section>

<section class="features-area pt-100 pb-70 bg-f4f6fc">
  <div class="container">
    <div class="section-title">
      <h2>Our amazing features</h2>
    </div>
    <div class="row">
      <div class="col-lg-4 col-md-6">
        <div class="features-box-one wow fadeInLeft" data-wow-delay=".2s">
          <i class="bx bx-conversation bg-13c4a1"></i>
          <h3>IT Consultancy</h3>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor dolore magna.
          </p>
        </div>
      </div>
      <div class="col-lg-4 col-md-6">
        <div class="features-box-one wow fadeInLeft" data-wow-delay=".4s">
          <i class="bx bxs-badge-check bg-6610f2"></i>
          <h3>IT Solutions</h3>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor dolore magna.
          </p>
        </div>
      </div>
      <div class="col-lg-4 col-md-6">
        <div class="features-box-one wow fadeInLeft" data-wow-delay=".6s">
          <i class="bx bxs-dashboard bg-ffb700"></i>
          <h3>Simple Dashboard</h3>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor dolore magna.
          </p>
        </div>
      </div>
      <div class="col-lg-4 col-md-6">
        <div class="features-box-one wow fadeInUp" data-wow-delay=".2s">
          <i class="bx bxs-bell-ring bg-fc3549"></i>
          <h3>Deadline Reminders</h3>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor dolore magna.
          </p>
        </div>
      </div>
      <div class="col-lg-4 col-md-6">
        <div class="features-box-one wow fadeInUp" data-wow-delay=".4s">
          <i class="bx bxs-info-circle bg-00d280"></i>
          <h3>Information Retrieval</h3>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor dolore magna.
          </p>
        </div>
      </div>
      <div class="col-lg-4 col-md-6">
        <div class="features-box-one wow fadeInUp" data-wow-delay=".6s">
          <i class="bx bx-cog bg-ff612f"></i>
          <h3>Flexible Functionality</h3>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor dolore magna.
          </p>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="feedback-area pt-100 pb-70">
  <div class="container">
    <div class="section-title">
      <h2>Whats Our Clients Said About <span>Jexsa</span></h2>
    </div>
    <div class="feedback-slides owl-carousel owl-theme">
      <div class="single-feedback-item">
        <img src="assets/img/woman1.png" alt="image" />
        <div class="feedback-desc">
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis
            ipsum suspendisse.
          </p>
          <div class="rating">
            <i class="bx bxs-star"></i>
            <i class="bx bxs-star"></i>
            <i class="bx bxs-star"></i>
            <i class="bx bxs-star"></i>
            <i class="bx bxs-star"></i>
          </div>
          <div class="client-info">
            <h3>Sarah Taylor</h3>
            <span>CEO at Envato</span>
          </div>
        </div>
      </div>
      <div class="single-feedback-item">
        <img src="assets/img/woman2.png" alt="image" />
        <div class="feedback-desc">
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis
            ipsum suspendisse.
          </p>
          <div class="rating">
            <i class="bx bxs-star"></i>
            <i class="bx bxs-star"></i>
            <i class="bx bxs-star"></i>
            <i class="bx bxs-star"></i>
            <i class="bx bxs-star"></i>
          </div>
          <div class="client-info">
            <h3>Olivar Lucy</h3>
            <span>CEO at EnvyTheme.com</span>
          </div>
        </div>
      </div>
      <div class="single-feedback-item">
        <img src="assets/img/man1.png" alt="image" />
        <div class="feedback-desc">
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis
            ipsum suspendisse.
          </p>
          <div class="rating">
            <i class="bx bxs-star"></i>
            <i class="bx bxs-star"></i>
            <i class="bx bxs-star"></i>
            <i class="bx bxs-star"></i>
            <i class="bx bxs-star"></i>
          </div>
          <div class="client-info">
            <h3>Steven Smith</h3>
            <span>CEO at Envato</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="pricing-area pt-100 pb-70 bg-f4f5fe">
  <div class="container">
    <div class="section-title">
      <h2>Choose The Pricing Plan</h2>
    </div>
    <div class="tab pricing-list-tab">
      <ul class="tabs">
        <li>
          <a href="#"><i class="bx bxs-calendar-check"></i> Monthly</a>
        </li>
        <li>
          <a href="#"><i class="bx bxs-calendar-check"></i> Yearly</a>
        </li>
      </ul>
      <div class="tab_content">
        <div class="tabs_item">
          <div class="row">
            <div class="col-lg-4 col-sm-6">
              <div class="single-pricing-table left-align">
                <div class="pricing-header">
                  <h3>Free</h3>
                </div>
                <div class="price"><sup>$</sup>0 <sub>/ monthly</sub></div>
                <ul class="pricing-features">
                  <li>
                    <i class="bx bxs-badge-check"></i> Up to 3 chat operators
                    <span
                      class="tooltips bx bxs-info-circle"
                      data-toggle="tooltip"
                      data-placement="right"
                      title="Tight pants next level keffiyeh you probably haven't heard of them."
                    ></span>
                  </li>

                  <li>
                    <i class="bx bxs-badge-check"></i> 100 ChatBot Triggers
                  </li>

                  <li><i class="bx bxs-badge-check"></i> 24/7 Live Chat</li>

                  <li>
                    <i class="bx bxs-badge-check"></i> Email Integration
                    <span
                      class="tooltips bx bxs-info-circle"
                      data-toggle="tooltip"
                      data-placement="right"
                      title="Tight pants next level keffiyeh you probably haven't heard of them."
                    ></span>
                  </li>

                  <li>
                    <i class="bx bxs-badge-check"></i>
                    Messenger Integration
                  </li>

                  <li>
                    <i class="bx bxs-badge-check"></i>
                    Visitor Info
                  </li>

                  <li>
                    <i class="bx bxs-badge-check"></i>
                    Mobile + Desktop Apps
                  </li>

                  <li>
                    <i class="bx bxs-badge-check"></i> Quick Responses
                    <span
                      class="tooltips bx bxs-info-circle"
                      data-toggle="tooltip"
                      data-placement="right"
                      title="Tight pants next level keffiyeh you probably haven't heard of them."
                    ></span>
                  </li>

                  <li>
                    <i class="bx bxs-badge-check"></i> Drag & Drop Widgets
                  </li>

                  <li>
                    <i class="bx bxs-badge-check"></i>
                    Visitor Notes
                    <span
                      class="tooltips bx bxs-info-circle"
                      data-toggle="tooltip"
                      data-placement="right"
                      title="Tight pants next level keffiyeh you probably haven't heard of them."
                    ></span>
                  </li>

                  <li>
                    <i class="bx bxs-badge-check"></i>
                    Google Analytics
                  </li>
                </ul>
                <div class="btn-box">
                  <a href="#" class="default-btn"
                    ><i class="bx bxs-hot"></i> Try It Free Now<span></span
                  ></a>
                </div>
              </div>
            </div>

            <div class="col-lg-4 col-sm-6">
              <div class="single-pricing-table left-align">
                <div class="pricing-header">
                  <h3>Starter</h3>
                </div>
                <div class="price"><sup>$</sup>49 <sub>/ monthly</sub></div>
                <ul class="pricing-features">
                  <li>
                    <i class="bx bxs-badge-check"></i> Up to 4 chat operators
                    <span
                      class="tooltips bx bxs-info-circle"
                      data-toggle="tooltip"
                      data-placement="right"
                      title="Tight pants next level keffiyeh you probably haven't heard of them."
                    ></span>
                  </li>

                  <li>
                    <i class="bx bxs-badge-check"></i> 150 ChatBot Triggers
                  </li>

                  <li><i class="bx bxs-badge-check"></i> 24/7 Live Chat</li>

                  <li>
                    <i class="bx bxs-badge-check"></i> Email Integration
                    <span
                      class="tooltips bx bxs-info-circle"
                      data-toggle="tooltip"
                      data-placement="right"
                      title="Tight pants next level keffiyeh you probably haven't heard of them."
                    ></span>
                  </li>

                  <li>
                    <i class="bx bxs-badge-check"></i>
                    Messenger Integration
                  </li>

                  <li>
                    <i class="bx bxs-badge-check"></i>
                    Visitor Info
                  </li>

                  <li>
                    <i class="bx bxs-badge-check"></i>
                    Mobile + Desktop Apps
                  </li>

                  <li>
                    <i class="bx bxs-badge-check"></i> Quick Responses
                    <span
                      class="tooltips bx bxs-info-circle"
                      data-toggle="tooltip"
                      data-placement="right"
                      title="Tight pants next level keffiyeh you probably haven't heard of them."
                    ></span>
                  </li>

                  <li>
                    <i class="bx bxs-badge-check"></i> Drag & Drop Widgets
                  </li>

                  <li>
                    <i class="bx bxs-badge-check"></i>
                    Visitor Notes
                    <span
                      class="tooltips bx bxs-info-circle"
                      data-toggle="tooltip"
                      data-placement="right"
                      title="Tight pants next level keffiyeh you probably haven't heard of them."
                    ></span>
                  </li>

                  <li>
                    <i class="bx bxs-badge-check"></i>
                    Google Analytics
                  </li>
                </ul>
                <div class="btn-box">
                  <a href="#" class="default-btn"
                    ><i class="bx bxs-hot"></i> Try It Free Now<span></span
                  ></a>
                </div>
              </div>
            </div>

            <div class="col-lg-4 col-sm-6 offset-lg-0 offset-sm-3">
              <div class="single-pricing-table left-align">
                <div class="pricing-header">
                  <h3>Professional</h3>
                </div>
                <div class="price"><sup>$</sup>79 <sub>/ monthly</sub></div>
                <ul class="pricing-features">
                  <li>
                    <i class="bx bxs-badge-check"></i> Up to 7 chat operators
                    <span
                      class="tooltips bx bxs-info-circle"
                      data-toggle="tooltip"
                      data-placement="right"
                      title="Tight pants next level keffiyeh you probably haven't heard of them."
                    ></span>
                  </li>

                  <li>
                    <i class="bx bxs-badge-check"></i> 250 ChatBot Triggers
                  </li>

                  <li><i class="bx bxs-badge-check"></i> 24/7 Live Chat</li>

                  <li>
                    <i class="bx bxs-badge-check"></i> Email Integration
                    <span
                      class="tooltips bx bxs-info-circle"
                      data-toggle="tooltip"
                      data-placement="right"
                      title="Tight pants next level keffiyeh you probably haven't heard of them."
                    ></span>
                  </li>

                  <li>
                    <i class="bx bxs-badge-check"></i>
                    Messenger Integration
                  </li>

                  <li>
                    <i class="bx bxs-badge-check"></i>
                    Visitor Info
                  </li>

                  <li>
                    <i class="bx bxs-badge-check"></i>
                    Mobile + Desktop Apps
                  </li>

                  <li>
                    <i class="bx bxs-badge-check"></i> Quick Responses
                    <span
                      class="tooltips bx bxs-info-circle"
                      data-toggle="tooltip"
                      data-placement="right"
                      title="Tight pants next level keffiyeh you probably haven't heard of them."
                    ></span>
                  </li>

                  <li>
                    <i class="bx bxs-badge-check"></i> Drag & Drop Widgets
                  </li>

                  <li>
                    <i class="bx bxs-badge-check"></i>
                    Visitor Notes
                    <span
                      class="tooltips bx bxs-info-circle"
                      data-toggle="tooltip"
                      data-placement="right"
                      title="Tight pants next level keffiyeh you probably haven't heard of them."
                    ></span>
                  </li>

                  <li>
                    <i class="bx bxs-badge-check"></i>
                    Google Analytics
                  </li>
                </ul>
                <div class="btn-box">
                  <a href="#" class="default-btn"
                    ><i class="bx bxs-hot"></i> Try It Free Now<span></span
                  ></a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="tabs_item">
          <div class="row">
            <div class="col-lg-4 col-md-6">
              <div class="single-pricing-table left-align">
                <div class="pricing-header">
                  <h3>Free</h3>
                </div>
                <div class="price"><sup>$</sup>0 <sub>/ yearly</sub></div>
                <ul class="pricing-features">
                  <li>
                    <i class="bx bxs-badge-check"></i> Up to 3 chat operators
                    <span
                      class="tooltips bx bxs-info-circle"
                      data-toggle="tooltip"
                      data-placement="right"
                      title="Tight pants next level keffiyeh you probably haven't heard of them."
                    ></span>
                  </li>

                  <li>
                    <i class="bx bxs-badge-check"></i> 100 ChatBot Triggers
                  </li>

                  <li><i class="bx bxs-badge-check"></i> 24/7 Live Chat</li>

                  <li>
                    <i class="bx bxs-badge-check"></i> Email Integration
                    <span
                      class="tooltips bx bxs-info-circle"
                      data-toggle="tooltip"
                      data-placement="right"
                      title="Tight pants next level keffiyeh you probably haven't heard of them."
                    ></span>
                  </li>

                  <li>
                    <i class="bx bxs-badge-check"></i>
                    Messenger Integration
                  </li>

                  <li>
                    <i class="bx bxs-badge-check"></i>
                    Visitor Info
                  </li>

                  <li>
                    <i class="bx bxs-badge-check"></i>
                    Mobile + Desktop Apps
                  </li>

                  <li>
                    <i class="bx bxs-badge-check"></i> Quick Responses
                    <span
                      class="tooltips bx bxs-info-circle"
                      data-toggle="tooltip"
                      data-placement="right"
                      title="Tight pants next level keffiyeh you probably haven't heard of them."
                    ></span>
                  </li>

                  <li>
                    <i class="bx bxs-badge-check"></i> Drag & Drop Widgets
                  </li>

                  <li>
                    <i class="bx bxs-badge-check"></i>
                    Visitor Notes
                    <span
                      class="tooltips bx bxs-info-circle"
                      data-toggle="tooltip"
                      data-placement="right"
                      title="Tight pants next level keffiyeh you probably haven't heard of them."
                    ></span>
                  </li>

                  <li>
                    <i class="bx bxs-badge-check"></i>
                    Google Analytics
                  </li>
                </ul>
                <div class="btn-box">
                  <a href="#" class="default-btn"
                    ><i class="bx bxs-hot"></i> Try It Free Now<span></span
                  ></a>
                </div>
              </div>
            </div>

            <div class="col-lg-4 col-md-6">
              <div class="single-pricing-table left-align">
                <div class="pricing-header">
                  <h3>Starter</h3>
                </div>
                <div class="price"><sup>$</sup>79 <sub>/ yearly</sub></div>
                <ul class="pricing-features">
                  <li>
                    <i class="bx bxs-badge-check"></i> Up to 4 chat operators
                    <span
                      class="tooltips bx bxs-info-circle"
                      data-toggle="tooltip"
                      data-placement="right"
                      title="Tight pants next level keffiyeh you probably haven't heard of them."
                    ></span>
                  </li>

                  <li>
                    <i class="bx bxs-badge-check"></i> 150 ChatBot Triggers
                  </li>

                  <li><i class="bx bxs-badge-check"></i> 24/7 Live Chat</li>

                  <li>
                    <i class="bx bxs-badge-check"></i> Email Integration
                    <span
                      class="tooltips bx bxs-info-circle"
                      data-toggle="tooltip"
                      data-placement="right"
                      title="Tight pants next level keffiyeh you probably haven't heard of them."
                    ></span>
                  </li>

                  <li>
                    <i class="bx bxs-badge-check"></i>
                    Messenger Integration
                  </li>

                  <li>
                    <i class="bx bxs-badge-check"></i>
                    Visitor Info
                  </li>

                  <li>
                    <i class="bx bxs-badge-check"></i>
                    Mobile + Desktop Apps
                  </li>

                  <li>
                    <i class="bx bxs-badge-check"></i> Quick Responses
                    <span
                      class="tooltips bx bxs-info-circle"
                      data-toggle="tooltip"
                      data-placement="right"
                      title="Tight pants next level keffiyeh you probably haven't heard of them."
                    ></span>
                  </li>

                  <li>
                    <i class="bx bxs-badge-check"></i> Drag & Drop Widgets
                  </li>

                  <li>
                    <i class="bx bxs-badge-check"></i>
                    Visitor Notes
                    <span
                      class="tooltips bx bxs-info-circle"
                      data-toggle="tooltip"
                      data-placement="right"
                      title="Tight pants next level keffiyeh you probably haven't heard of them."
                    ></span>
                  </li>

                  <li>
                    <i class="bx bxs-badge-check"></i>
                    Google Analytics
                  </li>
                </ul>
                <div class="btn-box">
                  <a href="#" class="default-btn"
                    ><i class="bx bxs-hot"></i> Try It Free Now<span></span
                  ></a>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 offset-lg-0 offset-md-3">
              <div class="single-pricing-table left-align">
                <div class="pricing-header">
                  <h3>Professional</h3>
                </div>
                <div class="price"><sup>$</sup>99 <sub>/ yearly</sub></div>
                <ul class="pricing-features">
                  <li>
                    <i class="bx bxs-badge-check"></i> Up to 7 chat operators
                    <span
                      class="tooltips bx bxs-info-circle"
                      data-toggle="tooltip"
                      data-placement="right"
                      title="Tight pants next level keffiyeh you probably haven't heard of them."
                    ></span>
                  </li>

                  <li>
                    <i class="bx bxs-badge-check"></i> 250 ChatBot Triggers
                  </li>

                  <li><i class="bx bxs-badge-check"></i> 24/7 Live Chat</li>

                  <li>
                    <i class="bx bxs-badge-check"></i> Email Integration
                    <span
                      class="tooltips bx bxs-info-circle"
                      data-toggle="tooltip"
                      data-placement="right"
                      title="Tight pants next level keffiyeh you probably haven't heard of them."
                    ></span>
                  </li>

                  <li>
                    <i class="bx bxs-badge-check"></i>
                    Messenger Integration
                  </li>

                  <li>
                    <i class="bx bxs-badge-check"></i>
                    Visitor Info
                  </li>

                  <li>
                    <i class="bx bxs-badge-check"></i>
                    Mobile + Desktop Apps
                  </li>

                  <li>
                    <i class="bx bxs-badge-check"></i> Quick Responses
                    <span
                      class="tooltips bx bxs-info-circle"
                      data-toggle="tooltip"
                      data-placement="right"
                      title="Tight pants next level keffiyeh you probably haven't heard of them."
                    ></span>
                  </li>

                  <li>
                    <i class="bx bxs-badge-check"></i> Drag & Drop Widgets
                  </li>

                  <li>
                    <i class="bx bxs-badge-check"></i>
                    Visitor Notes
                    <span
                      class="tooltips bx bxs-info-circle"
                      data-toggle="tooltip"
                      data-placement="right"
                      title="Tight pants next level keffiyeh you probably haven't heard of them."
                    ></span>
                  </li>

                  <li>
                    <i class="bx bxs-badge-check"></i>
                    Google Analytics
                  </li>
                </ul>
                <div class="btn-box">
                  <a href="#" class="default-btn"
                    ><i class="bx bxs-hot"></i> Try It Free Now<span></span
                  ></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="team-area pt-100 pb-70">
  <div class="container">
    <div class="section-title">
      <h2>Meet Our experts always ready to help you</h2>
    </div>
    <div class="row">
      <div class="col-lg-3 col-sm-6 col-md-6">
        <div class="single-team-box wow fadeInUp" data-wow-delay=".2s">
          <div class="image">
            <img src="assets/img/team-image/img1.jpg" alt="image" />
            <ul class="social">
              <li>
                <a href="#" target="_blank"><i class="bx bxl-facebook"></i></a>
              </li>
              <li>
                <a href="#" target="_blank"><i class="bx bxl-twitter"></i></a>
              </li>
              <li>
                <a href="#" target="_blank"><i class="bx bxl-linkedin"></i></a>
              </li>
              <li>
                <a href="#" target="_blank"><i class="bx bxl-instagram"></i></a>
              </li>
            </ul>
          </div>
          <div class="content">
            <h3>Alex Maxwel</h3>
            <span>CEO & Founder</span>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-sm-6 col-md-6">
        <div class="single-team-box wow fadeInUp" data-wow-delay=".4s">
          <div class="image">
            <img src="assets/img/team-image/img2.jpg" alt="image" />
            <ul class="social">
              <li>
                <a href="#" target="_blank"><i class="bx bxl-facebook"></i></a>
              </li>
              <li>
                <a href="#" target="_blank"><i class="bx bxl-twitter"></i></a>
              </li>
              <li>
                <a href="#" target="_blank"><i class="bx bxl-linkedin"></i></a>
              </li>
              <li>
                <a href="#" target="_blank"><i class="bx bxl-instagram"></i></a>
              </li>
            </ul>
          </div>
          <div class="content">
            <h3>Sarah Taylor</h3>
            <span>UX/UI Designer</span>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-sm-6 col-md-6">
        <div class="single-team-box wow fadeInUp" data-wow-delay=".6s">
          <div class="image">
            <img src="assets/img/team-image/img3.jpg" alt="image" />
            <ul class="social">
              <li>
                <a href="#" target="_blank"><i class="bx bxl-facebook"></i></a>
              </li>
              <li>
                <a href="#" target="_blank"><i class="bx bxl-twitter"></i></a>
              </li>
              <li>
                <a href="#" target="_blank"><i class="bx bxl-linkedin"></i></a>
              </li>
              <li>
                <a href="#" target="_blank"><i class="bx bxl-instagram"></i></a>
              </li>
            </ul>
          </div>
          <div class="content">
            <h3>Lee Munroe</h3>
            <span>Web Developer</span>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-sm-6 col-md-6">
        <div class="single-team-box wow fadeInUp" data-wow-delay=".8s">
          <div class="image">
            <img src="assets/img/team-image/img4.jpg" alt="image" />
            <ul class="social">
              <li>
                <a href="#" target="_blank"><i class="bx bxl-facebook"></i></a>
              </li>
              <li>
                <a href="#" target="_blank"><i class="bx bxl-twitter"></i></a>
              </li>
              <li>
                <a href="#" target="_blank"><i class="bx bxl-linkedin"></i></a>
              </li>
              <li>
                <a href="#" target="_blank"><i class="bx bxl-instagram"></i></a>
              </li>
            </ul>
          </div>
          <div class="content">
            <h3>Calvin Klein</h3>
            <span>Support</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="our-loving-clients ptb-100 bg-f4f5fe">
  <div class="container">
    <div class="section-title">
      <h2>Our Loving Clients</h2>
    </div>
    <div class="clients-logo-list align-items-center">
      <div class="single-clients-logo wow fadeInUp" data-wow-delay=".2s">
        <a href="#">
          <img src="assets/img/clients-image/img1.png" alt="image" />
        </a>
      </div>
      <div class="single-clients-logo wow fadeInUp" data-wow-delay=".4s">
        <a href="#">
          <img src="assets/img/clients-image/img2.png" alt="image" />
        </a>
      </div>
      <div class="single-clients-logo wow fadeInUp" data-wow-delay=".6s">
        <a href="#">
          <img src="assets/img/clients-image/img3.png" alt="image" />
        </a>
      </div>
      <div class="single-clients-logo wow fadeInUp" data-wow-delay=".8s">
        <a href="#">
          <img src="assets/img/clients-image/img4.png" alt="image" />
        </a>
      </div>
      <div class="single-clients-logo wow fadeInUp" data-wow-delay="1s">
        <a href="#">
          <img src="assets/img/clients-image/img5.png" alt="image" />
        </a>
      </div>
      <div class="single-clients-logo wow fadeInUp" data-wow-delay="1.2s">
        <a href="#">
          <img src="assets/img/clients-image/img6.png" alt="image" />
        </a>
      </div>
      <div class="single-clients-logo wow fadeInUp" data-wow-delay="1.4s">
        <a href="#">
          <img src="assets/img/clients-image/img7.png" alt="image" />
        </a>
      </div>
      <div class="single-clients-logo wow fadeInUp" data-wow-delay="1.6s">
        <a href="#">
          <img src="assets/img/clients-image/img8.png" alt="image" />
        </a>
      </div>
      <div class="single-clients-logo wow fadeInUp" data-wow-delay="1.8s">
        <a href="#">
          <img src="assets/img/clients-image/img9.png" alt="image" />
        </a>
      </div>
    </div>
  </div>
</section>

<section class="faq-area ptb-100">
  <div class="container-fluid">
    <div class="row align-items-center">
      <div class="col-lg-7 col-md-12">
        <div class="faq-accordion">
          <h2>Get to know about <span>Jexsa</span></h2>
          <div
            class="accordion"
            id="accordionEx"
            role="tablist"
            aria-multiselectable="true"
          >
            <div class="card">
              <div class="card-header" role="tab" id="headingOne">
                <a
                  data-toggle="collapse"
                  data-parent="#accordionEx"
                  href="#collapseOne"
                  aria-expanded="false"
                  aria-controls="collapseOne"
                >
                  What access do I have on the free plan?
                  <i class="bx bx-plus"></i>
                </a>
              </div>
              <div
                id="collapseOne"
                class="collapse show"
                role="tabpanel"
                aria-labelledby="headingOne"
                data-parent="#accordionEx"
              >
                <div class="card-body">
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s, when an unknown
                  printer took a galley of type and scrambled it to make a type
                  specimen book.
                </div>
              </div>
            </div>
            <div class="card">
              <div class="card-header" role="tab" id="headingTwo">
                <a
                  class="collapsed"
                  data-toggle="collapse"
                  data-parent="#accordionEx"
                  href="#collapseTwo"
                  aria-expanded="false"
                  aria-controls="collapseTwo"
                >
                  What access do I have on a free trial?
                  <i class="bx bx-plus"></i>
                </a>
              </div>
              <div
                id="collapseTwo"
                class="collapse"
                role="tabpanel"
                aria-labelledby="headingTwo"
                data-parent="#accordionEx"
              >
                <div class="card-body">
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s, when an unknown
                  printer took a galley of type and scrambled it to make a type
                  specimen book.
                </div>
              </div>
            </div>
            <div class="card">
              <div class="card-header" role="tab" id="headingThree">
                <a
                  class="collapsed"
                  data-toggle="collapse"
                  data-parent="#accordionEx"
                  href="#collapseThree"
                  aria-expanded="false"
                  aria-controls="collapseThree"
                >
                  Does the price go up as my team gets larger?
                  <i class="bx bx-plus"></i>
                </a>
              </div>
              <div
                id="collapseThree"
                class="collapse"
                role="tabpanel"
                aria-labelledby="headingThree"
                data-parent="#accordionEx"
              >
                <div class="card-body">
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s, when an unknown
                  printer took a galley of type and scrambled it to make a type
                  specimen book.
                </div>
              </div>
            </div>
            <div class="card">
              <div class="card-header" role="tab" id="headingFour">
                <a
                  class="collapsed"
                  data-toggle="collapse"
                  data-parent="#accordionEx"
                  href="#collapseFour"
                  aria-expanded="false"
                  aria-controls="collapseFour"
                >
                  How can I cancel/pause my subscription?
                  <i class="bx bx-plus"></i>
                </a>
              </div>
              <div
                id="collapseFour"
                class="collapse"
                role="tabpanel"
                aria-labelledby="headingFour"
                data-parent="#accordionEx"
              >
                <div class="card-body">
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s, when an unknown
                  printer took a galley of type and scrambled it to make a type
                  specimen book.
                </div>
              </div>
            </div>
            <div class="card mb-0">
              <div class="card-header" role="tab" id="headingFive">
                <a
                  class="collapsed"
                  data-toggle="collapse"
                  data-parent="#accordionEx"
                  href="#collapseFive"
                  aria-expanded="false"
                  aria-controls="collapseFive"
                >
                  Can I pay via an invoice?
                  <i class="bx bx-plus"></i>
                </a>
              </div>
              <div
                id="collapseFive"
                class="collapse"
                role="tabpanel"
                aria-labelledby="headingFive"
                data-parent="#accordionEx"
              >
                <div class="card-body">
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s, when an unknown
                  printer took a galley of type and scrambled it to make a type
                  specimen book.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-5 col-md-12">
        <div class="faq-image wow fadeInUp" data-wow-delay=".2s">
          <img src="assets/img/faq-img1.png" alt="image" />
        </div>
      </div>
    </div>
  </div>
</section>

<div class="subscribe-content border-radius-0">
  <h2>We always try to be the best support to you as possible</h2>
  <form class="newsletter-form">
    <div class="row align-items-center">
      <div class="col-lg-8 col-md-8">
        <input
          type="email"
          class="input-newsletter"
          placeholder="hello@jexsa.com"
          name="EMAIL"
          required
        />
      </div>
      <div class="col-lg-4 col-md-4">
        <button type="submit"><i class="bx bxs-hot"></i> Subscribe Now</button>
      </div>
    </div>
  </form>
  <div class="shape14">
    <img src="assets/img/shape/shape13.png" alt="image" />
  </div>
  <div class="shape15">
    <img src="assets/img/shape/shape14.png" alt="image" />
  </div>
  <div class="shape16">
    <img src="assets/img/shape/shape15.png" alt="image" />
  </div>
  <div class="shape17">
    <img src="assets/img/shape/shape16.png" alt="image" />
  </div>
  <div class="shape18">
    <img src="assets/img/shape/shape17.png" alt="image" />
  </div>
</div>

<section class="blog-area ptb-100">
  <div class="container">
    <div class="section-title">
      <h2>Our Latest News</h2>
    </div>
    <div class="row">
      <div class="col-lg-4 col-md-6">
        <div class="single-blog-post wow fadeInUp" data-wow-delay=".2s">
          <div class="post-image">
            <a routerLink="/blog-details"
              ><img src="assets/img/blog-image/img1.jpg" alt="image"
            /></a>
            <div class="date"><i class="bx bx-calendar"></i> Oct 14, 2020</div>
          </div>
          <div class="post-content">
            <h3>
              <a routerLink="/blog-details"
                >50 world-changing people, We lost in the 2010s</a
              >
            </h3>
            <div class="post-info">
              <div class="post-by">
                <img src="assets/img/author-image/img1.jpg" alt="image" />
                <h6>Sarah Taylor</h6>
              </div>
              <div class="details-btn">
                <a routerLink="/blog-details"
                  ><i class="bx bx-right-arrow-alt"></i
                ></a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-6">
        <div class="single-blog-post wow fadeInUp" data-wow-delay=".4s">
          <div class="post-image">
            <a routerLink="/blog-details"
              ><img src="assets/img/blog-image/img2.jpg" alt="image"
            /></a>
            <div class="date"><i class="bx bx-calendar"></i> Oct 16, 2020</div>
          </div>
          <div class="post-content">
            <h3>
              <a routerLink="/blog-details"
                >Don't buy a tech gift until you read these rules</a
              >
            </h3>
            <div class="post-info">
              <div class="post-by">
                <img src="assets/img/author-image/img2.jpg" alt="image" />
                <h6>Michel John</h6>
              </div>
              <div class="details-btn">
                <a routerLink="/blog-details"
                  ><i class="bx bx-right-arrow-alt"></i
                ></a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 offset-lg-0 offset-md-3">
        <div class="single-blog-post wow fadeInUp" data-wow-delay=".6s">
          <div class="post-image">
            <a routerLink="/blog-details"
              ><img src="assets/img/blog-image/img3.jpg" alt="image"
            /></a>
            <div class="date"><i class="bx bx-calendar"></i> Oct 18, 2020</div>
          </div>
          <div class="post-content">
            <h3>
              <a routerLink="/blog-details"
                >The golden rule of buying a phone as a gift</a
              >
            </h3>
            <div class="post-info">
              <div class="post-by">
                <img src="assets/img/author-image/img3.jpg" alt="image" />
                <h6>Lucy Eva</h6>
              </div>
              <div class="details-btn">
                <a routerLink="/blog-details"
                  ><i class="bx bx-right-arrow-alt"></i
                ></a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-12 col-md-12">
        <div class="blog-notes wow fadeInUp" data-wow-delay=".8s">
          <p>
            Insights to help you do what you do better, faster and more
            profitably.
            <a routerLink="/blog-1">Read Full Blog</a>
          </p>
        </div>
      </div>
    </div>
  </div>
</section>
