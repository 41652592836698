import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-blog-right-sidebar',
  templateUrl: './blog-right-sidebar.component.html',
  styleUrls: ['./blog-right-sidebar.component.scss']
})
export class BlogRightSidebarComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
