<div class="saas-banner">
  <div class="d-table">
    <div class="d-table-cell">
      <div class="container max-width-1290">
        <div class="row align-items-center pt-5">
          <div class="col-lg-6 col-md-12">
            <div class="saas-image mt-70">
              <img
                src="assets/img/saas-shape/arrow.png"
                class="wow fadeInDown"
                data-wow-delay="0.6s"
                alt="arrow"
              />
              <img
                src="assets/img/saas-shape/box1.png"
                class="wow fadeInUp"
                data-wow-delay="0.6s"
                alt="box1"
              />
              <img
                src="assets/img/saas-shape/boy1.png"
                class="wow fadeInLeft"
                data-wow-delay="0.6s"
                alt="boy1"
              />
              <img
                src="assets/img/saas-shape/boy2.png"
                class="wow zoomIn"
                data-wow-delay="0.6s"
                alt="boy2"
              />
              <img
                src="assets/img/saas-shape/boy3.png"
                class="wow bounceIn"
                data-wow-delay="0.6s"
                alt="boy3"
              />
              <img
                src="assets/img/saas-shape/digital-screen.png"
                class="wow fadeInDown"
                data-wow-delay="0.6s"
                alt="digital-screen"
              />
              <img
                src="assets/img/saas-shape/filter1.png"
                class="wow zoomIn"
                data-wow-delay="0.6s"
                alt="filter1"
              />
              <img
                src="assets/img/saas-shape/filter2.png"
                class="wow fadeInUp"
                data-wow-delay="0.6s"
                alt="filter2"
              />
              <img
                src="assets/img/saas-shape/filter3.png"
                class="wow rotateIn"
                data-wow-delay="0.6s"
                alt="filter3"
              />
              <img
                src="assets/img/saas-shape/girl1.png"
                class="wow fadeInUp"
                data-wow-delay="0.6s"
                alt="girl1"
              />
              <img
                src="assets/img/saas-shape/girl2.png"
                class="wow zoomIn"
                data-wow-delay="0.6s"
                alt="girl2"
              />
              <img
                src="assets/img/saas-shape/monitor.png"
                class="wow zoomIn"
                data-wow-delay="0.6s"
                alt="monitor"
              />
              <!-- Main image -->
              <img
                src="assets/img/saas-shape/main-image.png"
                class="wow zoomIn"
                data-wow-delay="0.6s"
                alt="main-image.png"
              />
            </div>
          </div>
          <div class="col-lg-6 col-md-12">
            <div class="hero-content pl-4">
              <h1>Manage your business with one application</h1>
              <p>
                Joining as a Pro user with B Space will make managing your
                business easier. Look at our many features below!
              </p>
              <div class="banner-btn">
                <div class="d-flex">
                  <a href="/#contact-section" class="default-btn">
                    <!-- <i class="bx bxs-hot"></i>  -->
                    Join as a Pro <span></span
                  ></a>
                  <a
                    href="https://www.youtube.com/watch?v=gR6Oy-DKj2g"
                    class="video-btn popup-youtube"
                  >
                    <i class="bx bxs-right-arrow"></i> Watch Video
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="shape-rotate rotateme">
    <img src="assets/img/saas-shape/shape-rotate.png" alt="img" />
  </div>
  <div id="particles-js"></div>
</div>

<a name="features"></a>
<section class="features-area pt-100 pb-70 bg-green-white">
  <div class="container max-width-1290">
    <div class="section-title">
      <h2>Features</h2>
    </div>
    <div class="row">
      <div class="col-lg-6 col-md-6">
        <div class="features-box-one wow fadeInLeft" data-wow-delay=".1s">
          <i class="bx bx-user-voice bg-dark"></i>
          <h3>Get More Clients</h3>
          <p>
            When you join B Space you also join our our network. We work hard to
            secure you clients because when you succeed, we succeed.
          </p>
        </div>
      </div>
      <div class="col-lg-6 col-md-6">
        <div class="features-box-one wow fadeInLeft" data-wow-delay=".2s">
          <i class="bx bxs-paint-roll bg-yellow"></i>
          <h3>Faster Drafting and Designs</h3>
          <p>
            B Space lets you draft anywhere, anytime. Our 1-click AI design
            feature makes interior designing easy.
          </p>
        </div>
      </div>
      <div class="col-lg-6 col-md-6">
        <div class="features-box-one wow fadeInLeft" data-wow-delay=".3s">
          <i class="bx bx-dollar-circle bg-green"></i>
          <h3>Immediate Quotations & Quick Invoicing</h3>
          <p>
            Immediately generate quotations after a design is made and be ready
            to invoice your clients.
          </p>
        </div>
      </div>
      <div class="col-lg-6 col-md-6">
        <div class="features-box-one wow fadeInLeft" data-wow-delay=".4s">
          <i class="bx bxs-truck bg-red"></i>
          <h3>One-Stop-Shop & Delivery</h3>
          <p>
            Everything you need to build your design will be purchasable from
            one shop. We’ll also deliver it to you, so you can focus on building
            it.
          </p>
        </div>
      </div>
      <!-- <div class="col-lg-6 col-md-6">
        <div class="features-box-one wow fadeInLeft" data-wow-delay=".5s">
          <i class="bx bxs-lock-open bg-00d280"></i>
          <h3>Quick Access</h3>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor dolore magna.
          </p>
        </div>
      </div>
      <div class="col-lg-4 col-md-6">
        <div class="features-box-one wow fadeInLeft" data-wow-delay=".6s">
          <i class="bx bxs-droplet-half bg-ff612f"></i>
          <h3>Drag and Drop</h3>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor dolore magna.
          </p>
        </div>
      </div> -->
    </div>
  </div>
</section>

<section class="coming-soon-area pb-70 bg-green-white">
  <div class="container max-width-1290">
    <img src="assets/img/b-space-logo.png" alt="logo" />
    <h1>is coming</h1>
    <h1>October 2020</h1>
  </div>
</section>

<div class="bg-white">
  <section class="services-area pb-70 pt-70">
    <div class="section-title">
      <h2>Drafting Innovation</h2>
    </div>
    <div class="container-fluid">
      <div class="row align-items-center">
        <div class="services-image wow fadeInLeft" data-wow-delay=".3s">
          <div class="image">
            <img
              src="assets/img/innovations-and-drafting-section/mac-1.png"
              alt="image"
            />
          </div>
        </div>
        <div class="services-content it-service-content">
          <div class="content">
            <div class="features-inner-content">
              <div class="features-item wow fadeInRight" data-wow-delay=".1s">
                <i class="bx bx-run bg-c160a5 blt-radius-0"></i>
                <h3>Take B Space Anywhere</h3>
                <p>
                  B Space is browser-based so you can log in using any device.
                  Take B Space directly to your client and draft on-site.
                </p>
              </div>
              <div class="features-item wow fadeInRight" data-wow-delay=".2s">
                <i class="bx bx-ruler bg-ce2335 blt-radius-0"></i>
                <h3>Save Your Work and Change Layouts</h3>
                <p>
                  Everything can be saved. Whether you’re saving a layout to get
                  measurements, or modifying an existing design, we can keep
                  your files safe.
                </p>
              </div>
              <!-- <div class="features-item wow fadeInRight" data-wow-delay=".3s">
              <i class="bx bx-code-alt bg-ff612f blt-radius-0"></i>
              <h3>QR Code Scaner</h3>
              <p>
                Plan ahead by day, week, or month, and see project status at a
                glance. Search and filter to focus in on anything form a single
                project to an individual person's workload.
              </p>
            </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="shape9">
    <img src="assets/img/shape/shape9.png" alt="image" />
  </div> -->
  </section>

  <section class="services-area pb-70">
    <div class="container-fluid">
      <div class="row align-items-center">
        <div class="services-content it-service-content">
          <div class="content">
            <div class="features-inner-content">
              <div class="features-item wow fadeInRight" data-wow-delay=".1s">
                <i
                  class="bx bx-brain bg-3aa788 blt-radius-0"
                  style="font-family: Sans-Serif !important;"
                  >AI</i
                >
                <h3>Interior Designs in 1-click</h3>
                <p>
                  Make the layout and click; our software will design the
                  interior! Our 1-click AI design feature will save you time and
                  money on all your projects.
                </p>
              </div>
              <div class="features-item wow fadeInRight" data-wow-delay=".2s">
                <i class="bx bx-edit bg-37308e blt-radius-0"></i>
                <h3>Flexible Editing</h3>
                <p>
                  Modify any portion of the design. Walls, floors and even the
                  furniture can be edited.
                </p>
              </div>
              <div class="features-item wow fadeInRight" data-wow-delay=".3s">
                <i class="bx bx-layout bg-f26867 blt-radius-0"></i>
                <h3>Designs with Instructions</h3>
                <p>
                  Our designs come with easy-to-read instructions! When you
                  order from us, we’ll make sure you’re able to build it.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="services-image wow fadeInLeft" data-wow-delay=".3s">
          <div class="image">
            <img
              src="assets/img/innovations-and-drafting-section/mac-2.png"
              alt="image"
            />
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="shape9">
    <img src="assets/img/shape/shape9.png" alt="image" />
  </div> -->
  </section>

  <section class="services-area pt-70">
    <div class="container-fluid">
      <div class="row align-items-center">
        <div class="services-image wow fadeInLeft" data-wow-delay=".3s">
          <div class="image">
            <img
              src="assets/img/innovations-and-drafting-section/mac-3.png"
              alt="image"
            />
          </div>
        </div>
        <div class="services-content it-service-content">
          <div class="content mac-and-phone-service-content">
            <div class="phone-image">
              <img
                src="assets/img/innovations-and-drafting-section/phone-1.png"
              />
            </div>
            <div class="features-inner-content" id="client-viewing">
              <div
                class="features-item wow fadeInRight wording-adjustment"
                data-wow-delay=".1s"
              >
                <i
                  class="bx bx-camera blt-radius-0"
                  style="background: #f68a1f;"
                ></i>
                <h3>Client Viewing</h3>
                <p>
                  All projects will be rendered in 3D. Enjoy a 720° rendered
                  design that can be sent to your clients.
                </p>
                <br />
                <a href="#demo">View our demos</a>
              </div>
              <!-- <div class="features-item wow fadeInRight" data-wow-delay=".2s">
                <i class="bx bx-smile bg-6610f2 blt-radius-0"></i>
                <h3>Feedback & Client Satisfaction</h3>
                <p>
                  Gain valuable feedback with a 720° rendered design to ensure
                  client satisfaction.
                </p>
              </div> -->
              <!-- <div class="features-item wow fadeInRight" data-wow-delay=".3s">
              <i class="bx bx-code-alt bg-ff612f blt-radius-0"></i>
              <h3>QR Code Scaner</h3>
              <p>
                Plan ahead by day, week, or month, and see project status at a
                glance. Search and filter to focus in on anything form a single
                project to an individual person's workload.
              </p>
            </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="shape9">
    <img src="assets/img/shape/shape9.png" alt="image" />
  </div> -->
  </section>
</div>

<div class="bg-white">
  <section class="showrooms-area pb-70 pt-100">
    <div class="section-title">
      <h2 class="mb-2">Showrooms</h2>
    </div>
    <div class="showroom-container">
      <div class="section-subtitle">
        <div
          class="features-item wow fadeInRight wording-adjustment"
          data-wow-delay=".1s"
        >
          <h3>
            <i class="bx bx-show-alt" style="background: #358bc0;"></i
            >&nbsp;&nbsp;&nbsp;&nbsp;See and Feel Materials
          </h3>
        </div>
        <div
          class="features-item wow fadeInRight wording-adjustment"
          data-wow-delay=".1s"
        >
          <p class="section-content">
            B Space offers showrooms where customers can come and see all of our
            materials <br />Materials can be chosen and scanned into their own
            personal projects<br />Additional staff and design teams are also
            present for assistance
          </p>
        </div>
      </div>
      <div
        class="features-item wow fadeInLeft"
        data-wow-delay=".1s"
        style="width: 100%;"
      >
        <div class="showroom-photo-row">
          <img src="assets/img/showroom/showroom_photo_1.png" />
          <img src="assets/img/showroom/showroom_photo_2.png" />
        </div>
      </div>
    </div>
    <div class="showroom-container">
      <div class="section-subtitle">
        <div
          class="features-item wow fadeInRight wording-adjustment"
          data-wow-delay=".1s"
        >
          <h3>
            <i class="bx bx-street-view" style="background: #d55858;"></i
            >&nbsp;&nbsp;&nbsp;&nbsp;Experience Virtual Reality
          </h3>
        </div>
        <div
          class="features-item wow fadeInRight wording-adjustment"
          data-wow-delay=".1s"
        >
          <p class="section-content">
            See and interact with your designs using state-of-the-art
            technology<br />Experience your newly chosen materials and see how
            it really fits in your layout
          </p>
        </div>
      </div>
      <div
        class="features-item wow fadeInLeft"
        data-wow-delay=".1s"
        style="width: 100%;"
      >
        <div class="showroom-photo-row">
          <img src="assets/img/showroom/showroom_photo_3.png" />
          <img src="assets/img/showroom/showroom_photo_4.png" />
        </div>
      </div>
    </div>
  </section>
</div>

<section class="ptb-100 bg-green-white">
  <div class="container">
    <div class="section-title">
      <h2 class="mb-2">One-Stop-Shop & Project Quotations</h2>
      <!-- <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
        tempor incididunt ut labore et dolore magna aliqua.
      </p> -->
    </div>
  </div>
  <!-- <div class="overview-item wow fadeInUp" data-wow-delay=".2s">
    <div class="container max-width-1290">
      <div class="row align-items-center">
        <div class="col-lg-6 col-md-6">
          <div class="overview-left-img">
            <img src="assets/img/home-saas/feature2.png" alt="image" />
          </div>
        </div>
        <div class="col-lg-6 col-md-6">
          <div class="overview-content pl-3">
            <span class="number">01</span>
            <h3>Getting Started Page</h3>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo.
            </p>
            <ul>
              <li><i class="bx bx-badge-check"></i>Unique Design</li>
              <li><i class="bx bx-badge-check"></i>Unimited Video Call</li>
              <li><i class="bx bx-badge-check"></i>Add Favourite contact</li>
              <li><i class="bx bx-badge-check"></i>Camera Filter</li>
            </ul>
            <a routerLink="/about" class="default-btn black-btn"
              ><i class="bx bxs-arrow-to-right"></i>Read More <span></span
            ></a>
          </div>
        </div>
      </div>
    </div>
  </div> -->
  <div class="overview-item wow fadeInUp" data-wow-delay=".4s">
    <div class="container max-width-1290">
      <div class="row align-items-center">
        <div class="col-lg-6 col-md-6">
          <div class="overview-content pl-3">
            <div class="row">
              <div class="col-lg-2"><span class="number">01</span></div>
              <div class="col-lg-10">
                <h3>All Your Supplies From B Space</h3>
                <p>
                  If it’s in a B Space design, you can buy it from B Space.
                  Purchase all your materials and even the furniture from our B
                  Space store.
                </p>
              </div>
            </div>
            <br />
            <div class="row">
              <div class="col-lg-2"><span class="number">02</span></div>
              <div class="col-lg-10">
                <h3>Equipment Rentals</h3>
                <p>
                  Need the right tool for the job? B Space also offers equipment
                  rentals.
                </p>
              </div>
            </div>
            <!-- <ul>
              <li><i class="bx bx-badge-check"></i>Mordan Design</li>
              <li><i class="bx bx-badge-check"></i>Unimited Video Call</li>
              <li><i class="bx bx-badge-check"></i>Add Favourite contact</li>
              <li><i class="bx bx-badge-check"></i>Camera Filter</li>
              <li><i class="bx bx-badge-check"></i>Outdated Comments</li>
            </ul>
            <a routerLink="/about" class="default-btn black-btn"
              ><i class="bx bxs-arrow-to-right"></i>Read More <span></span
            ></a> -->
          </div>
        </div>
        <div class="col-lg-6 col-md-6">
          <div class="overview-right-img">
            <img
              src="assets/img/shop-and-delivery-section/shop.png"
              alt="shop"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="overview-item wow fadeInUp" data-wow-delay=".6s">
    <div class="container max-width-1290">
      <div class="row align-items-center">
        <div class="col-lg-6 col-md-6">
          <div class="overview-left-img">
            <img
              src="assets/img/questions-and-invoicing-section/price_comparison.png"
              alt="image"
            />
          </div>
        </div>
        <div class="col-lg-6 col-md-6">
          <div class="overview-content pl-3">
            <div class="row">
              <div class="col-lg-2"><span class="number">03</span></div>
              <div class="col-lg-9">
                <h3>Get The Best Price</h3>
                <p>
                  We compare market prices, so you don’t have to! Use our price
                  comparison tool to ensure you’re always getting the best price
                  for your materials.
                </p>
              </div>
            </div>
            <!-- <ul>
              <li><i class="bx bx-badge-check"></i>Professional Code</li>

              <li><i class="bx bx-badge-check"></i>Unimited Video Call</li>
              <li><i class="bx bx-badge-check"></i>Add Favourite contact</li>
              <li><i class="bx bx-badge-check"></i>Camera Filter</li>
              <li><i class="bx bx-badge-check"></i>Start Coding Format</li>
            </ul>
            <a routerLink="/about" class="default-btn black-btn"
              ><i class="bx bxs-arrow-to-right"></i>Read More <span></span
            ></a> -->
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="overview-item wow fadeInUp" data-wow-delay=".4s">
    <div class="container max-width-1290">
      <div class="row align-items-center">
        <div class="col-lg-6 col-md-6">
          <div class="overview-content pl-3">
            <div class="row">
              <div class="col-lg-2"><span class="number">04</span></div>
              <div class="col-lg-10">
                <h3>Quick Project Quotations</h3>
                <p>
                  No more calcuating dollars and percentages. Our software
                  calculates everything you need and tells you how much your
                  project should cost.
                </p>
              </div>
            </div>
            <!-- <ul>
              <li><i class="bx bx-badge-check"></i>Mordan Design</li>
              <li><i class="bx bx-badge-check"></i>Unimited Video Call</li>
              <li><i class="bx bx-badge-check"></i>Add Favourite contact</li>
              <li><i class="bx bx-badge-check"></i>Camera Filter</li>
              <li><i class="bx bx-badge-check"></i>Outdated Comments</li>
            </ul>
            <a routerLink="/about" class="default-btn black-btn"
              ><i class="bx bxs-arrow-to-right"></i>Read More <span></span
            ></a> -->
          </div>
        </div>
        <div class="col-lg-6 col-md-6">
          <div class="overview-right-img">
            <img
              src="assets/img/questions-and-invoicing-section/question.png"
              alt="question"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="ptb-100 bg-green-white">
  <div class="container">
    <div class="section-title">
      <h2 class="mb-2">Delivery & Invoicing</h2>
    </div>
  </div>

  <div class="overview-item wow fadeInUp" data-wow-delay=".4s">
    <div class="container max-width-1290">
      <div class="row align-items-center">
        <div class="col-lg-6 col-md-6">
          <div class="overview-left-img">
            <img
              src="assets/img/shop-and-delivery-section/delivery.png"
              alt="delivery"
            />
          </div>
        </div>
        <div class="col-lg-6 col-md-6">
          <div class="overview-content pl-3">
            <div class="row">
              <div class="col-lg-2"><span class="number">05</span></div>
              <div class="col-lg-9">
                <h3>Delivery to Your Door</h3>
                <p>
                  If B Space is listed in your area, we will come to you. Have
                  all of your materials delivered on-site so you can start
                  building.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="overview-item wow fadeInUp" data-wow-delay=".6s">
    <div class="container max-width-1290">
      <div class="row align-items-center">
        <div class="col-lg-6 col-md-6">
          <div class="overview-content pl-3">
            <div class="row">
              <div class="col-lg-2"><span class="number">06</span></div>
              <div class="col-lg-9">
                <h3>3-Minute Invoice</h3>
                <p>
                  Invoicing made easy. B Space uses your information with our
                  data to generate a client invoice in a few minutes. This makes
                  getting paid simple.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-md-6">
          <div class="overview-right-img">
            <img
              src="assets/img/questions-and-invoicing-section/Slice 1-Color 4.png"
              alt="image"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="ptb-100 bg-green-white">
  <div class="container">
    <div class="section-title">
      <h2 class="mb-2">Customer Service</h2>
    </div>
  </div>

  <div class="overview-item wow fadeInUp" data-wow-delay=".6s">
    <div class="container max-width-1290">
      <div class="row align-items-center">
        <div class="col-lg-6 col-md-6">
          <div class="overview-left-img">
            <img
              src="assets/img/customer-service/online_support.png"
              alt="delivery"
            />
          </div>
        </div>
        <div class="col-lg-6 col-md-6">
          <div class="overview-content pl-3">
            <div class="row">
              <div class="col-lg-2"><span class="number">07</span></div>
              <div class="col-lg-9">
                <h3>Online Support</h3>
                <p>
                  Our support team is ready to help. In addition to our online
                  resources, we also have a team that can be reached via chat,
                  email or phone call.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<a name="demo"></a>
<section class="video-presentation-area ptb-100">
  <div class="container">
    <div class="section-title">
      <h2>Project Demos</h2>
    </div>
    <div class="video-box">
      <img
        src="assets/img/demos-section/video_main_qr_code.png"
        class="main-image"
        alt="image"
      />

      <!-- http://download.geashow.com/upload/s720/self/4/tour.html -->
      <a
        href="https://www.youtube.com/watch?v=gR6Oy-DKj2g"
        target="_blank"
        class="video-btn popup-youtube"
        ><i class="bx bx-play"></i
      ></a>
      <!-- <div class="shape1">
        <img src="assets/img/shape/shape1.png" alt="image" />
      </div>
      <div class="shape2">
        <img src="assets/img/shape/shape2.png" alt="image" />
      </div>
      <div class="shape3">
        <img src="assets/img/shape/shape3.png" alt="image" />
      </div>
      <div class="shape4">
        <img src="assets/img/shape/shape4.png" alt="image" />
      </div>
      <div class="shape5">
        <img src="assets/img/shape/shape5.png" alt="image" />
      </div>
      <div class="shape6">
        <img src="assets/img/shape/shape6.png" alt="image" />
      </div> -->
    </div>
    <!-- <div class="funfacts-inner">
      <div class="row">
        <div class="col-lg-3 col-6 col-sm-6">
          <div
            class="single-funfacts funfact-style-two wow fadeInLeft"
            data-wow-delay=".2s"
          >
            <i class="bx bx-list-check"></i>
            <h3>
              <span class="odometer" data-count="900">00</span
              ><span class="sign-icon">+</span>
            </h3>
            <p>Completed Projects</p>
          </div>
        </div>
        <div class="col-lg-3 col-6 col-sm-6">
          <div
            class="single-funfacts funfact-style-two wow fadeInLeft"
            data-wow-delay=".4s"
          >
            <i class="bx bx-smile"></i>
            <h3>
              <span class="odometer" data-count="850">00</span
              ><span class="sign-icon">+</span>
            </h3>
            <p>Happy Clients</p>
          </div>
        </div>
        <div class="col-lg-3 col-6 col-sm-6">
          <div
            class="single-funfacts funfact-style-two wow fadeInLeft"
            data-wow-delay=".6s"
          >
            <i class="bx bx-grid-small"></i>
            <h3>
              <span class="odometer" data-count="120">00</span
              ><span class="sign-icon">+</span>
            </h3>
            <p>Ongoing Projects</p>
          </div>
        </div>
        <div
          class="col-lg-3 col-6 col-sm-6 wow fadeInLeft"
          data-wow-delay=".8s"
        >
          <div class="single-funfacts funfact-style-two">
            <i class="bx bxs-award"></i>
            <h3>
              <span class="odometer" data-count="50">00</span
              ><span class="sign-icon">+</span>
            </h3>
            <p>Winning Awards</p>
          </div>
        </div>
      </div>
    </div> -->
    <!-- <div class="contact-cta-box mwidth-1000 wow fadeInUp" data-wow-delay="1s">
      <h3>Have any question about us?</h3>
      <p>Don't hesitate to contact us.</p>
      <a routerLink="/contact" class="default-btn"
        ><i class="bx bxs-edit-alt"></i> Contact Us<span></span
      ></a>
    </div> -->
  </div>
  <div class="container">
    <div class="row align-items-center pt-70">
      <div class="col-lg-4 col-md-12 pt-15">
        <div class="sm-video-box fadeInLeft">
          <div>
            <a
              href="http://ec2-3-83-82-3.compute-1.amazonaws.com/A002-1/tour.html"
              target="_blank"
            >
              <img src="assets/img/demos-section/Thumbnail 6.png" alt="demo" />
            </a>
          </div>
          <br />
          <div class="flex space-between others-options">
            <p class="fg-grey" style="font-size: 12px;">
              John's House 4200 sqft
            </p>
            <div>
              <a
                href="http://ec2-3-83-82-3.compute-1.amazonaws.com/A002-1/tour.html"
                class="video-btn default-btn-sm-outlined"
                style="font-size: 12px;"
                target="_blank"
              >
                Tour Room
              </a>
              <!-- &nbsp;
              <a
                href="https://www.youtube.com/watch?v=xq7g3K_ST98"
                class="video-btn popup-youtube default-btn-sm"
                style="font-size: 12px;"
              >
                View Demo
              </a> -->
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-12 pt-15">
        <div class="sm-video-box fadeInLeft">
          <div>
            <a
              href="http://download.geashow.com/upload/s720/company/zowee/Modern4/tour.html"
              target="_blank"
            >
              <img
                src="assets/img/demos-section/video_3_qr_code.png"
                alt="demo"
              />
            </a>
          </div>
          <br />
          <div class="flex space-between others-options">
            <p class="fg-grey" style="font-size: 11px;">
              Derek's House 900 sqft
            </p>
            <div>
              <a
                href="http://download.geashow.com/upload/s720/company/zowee/Modern4/tour.html"
                target="_blank"
                class="video-btn default-btn-sm-outlined"
                style="font-size: 12px;"
              >
                Tour Room </a
              >&nbsp;
              <a
                href="https://www.youtube.com/watch?v=xq7g3K_ST98"
                class="video-btn popup-youtube default-btn-sm"
                style="font-size: 12px;"
              >
                View Demo
              </a>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="col-lg-4 col-md-12 pt-15">
        <div class="sm-video-box fadeInLeft">
          <div>
            <a
              href="http://download.geashow.com/upload/s720/company/zowee/loft1/tour.html"
              target="_blank"
            >
              <img
                src="assets/img/demos-section/video_2_qr_code.png"
                alt="demo"
              />
            </a>
          </div>
          <br />
          <div class="flex space-between others-options">
            <p class="fg-grey" style="font-size: 12px;">
              Jane's House 3200 sqft
            </p>
            <div>
              <a
                href="http://download.geashow.com/upload/s720/company/zowee/loft1/tour.html"
                target="_blank"
                class="video-btn default-btn-sm-outlined"
                style="font-size: 12px;"
              >
                Tour Room </a
              >&nbsp;
              <a
                href="https://www.youtube.com/watch?v=HCuXWoKck-k"
                class="video-btn popup-youtube default-btn-sm"
                style="font-size: 12px;"
              >
                View Demo
              </a>
            </div>
          </div>
        </div>
      </div> -->
      <div class="col-lg-4 col-md-12 pt-15">
        <div class="sm-video-box fadeInLeft">
          <div>
            <a
              href="http://download.geashow.com/upload/s720/company/zowee/Modern2Change/tour.html"
              target="_blank"
            >
              <img
                src="assets/img/demos-section/video_4_qr_code.png"
                alt="demo"
              />
            </a>
          </div>
          <br />
          <div class="flex space-between others-options">
            <p class="fg-grey" style="font-size: 11px;">
              Anthony's House 1500 sqft
            </p>
            <div>
              <a
                href="http://download.geashow.com/upload/s720/company/zowee/Modern2Change/tour.html"
                target="_blank"
                class="video-btn default-btn-sm-outlined"
                style="font-size: 12px;"
              >
                Tour Room </a
              >&nbsp;
              <a
                href="https://www.youtube.com/watch?v=DiQGQ_GhQUc"
                class="video-btn popup-youtube default-btn-sm"
                style="font-size: 12px;"
              >
                View Demo
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="shape-map1"><img src="assets/img/map1.png" alt="image" /></div>
  <div class="shape7">
    <img src="assets/img/shape/shape7.png" alt="image" />
  </div>
  <div class="shape8">
    <img src="assets/img/shape/shape8.png" alt="image" />
  </div>
  <div class="shape9">
    <img src="assets/img/shape/shape9.png" alt="image" />
  </div>
</section>

<a name="contact-section"></a>
<!-- <pre>success: {{ success | async | json }}</pre>
<pre>loading: {{ loading | async | json }}</pre>
<pre>error: {{ error | async | json }}</pre> -->
<app-contact-form></app-contact-form>
<!-- <section class="services-area ptb-100 bg-green-white">
  <div class="container-fluid">
    <div class="row align-items-center">
      <div class="services-image wow fadeInLeft" data-wow-delay=".3s">
        <div class="image">
          <img src="assets/img/home-saas/feature5.png" alt="image" />
        </div>
      </div>
      <div class="services-content it-service-content">
        <div class="content">
          <div class="fun-facts-inner-content">
            <h2>Build Beautiful Interface Into Your Application</h2>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip.
            </p>
            <ul>
              <li><i class="bx bx-badge-check"></i>Professional Code</li>
              <li><i class="bx bx-badge-check"></i>Unimited Video Call</li>
              <li><i class="bx bx-badge-check"></i>Add Favourite contact</li>
              <li><i class="bx bx-badge-check"></i>Camera Filter</li>
              <li><i class="bx bx-badge-check"></i>Start Coding Format</li>
            </ul>
            <a routerLink="/about" class="default-btn black-btn"
              ><i class="bx bxs-arrow-to-right"></i>Read More <span></span
            ></a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="shape9">
    <img src="assets/img/shape/shape9.png" alt="image" />
  </div>
</section> -->

<!-- <section class="feedback-area pt-100 pb-70">
  <div class="container">
    <div class="section-title">
      <h2>Whats Our Clients Said About <span>Jexsa</span></h2>
    </div>
    <div class="feedback-slides owl-carousel owl-theme">
      <div class="single-feedback-item">
        <img src="assets/img/woman1.png" alt="image" />
        <div class="feedback-desc">
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis
            ipsum suspendisse.
          </p>
          <div class="rating">
            <i class="bx bxs-star"></i>
            <i class="bx bxs-star"></i>
            <i class="bx bxs-star"></i>
            <i class="bx bxs-star"></i>
            <i class="bx bxs-star"></i>
          </div>
          <div class="client-info">
            <h3>Sarah Taylor</h3>
            <span>CEO at Envato</span>
          </div>
        </div>
      </div>
      <div class="single-feedback-item">
        <img src="assets/img/woman2.png" alt="image" />
        <div class="feedback-desc">
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis
            ipsum suspendisse.
          </p>
          <div class="rating">
            <i class="bx bxs-star"></i>
            <i class="bx bxs-star"></i>
            <i class="bx bxs-star"></i>
            <i class="bx bxs-star"></i>
            <i class="bx bxs-star"></i>
          </div>
          <div class="client-info">
            <h3>Olivar Lucy</h3>
            <span>CEO at EnvyTheme.com</span>
          </div>
        </div>
      </div>
      <div class="single-feedback-item">
        <img src="assets/img/man1.png" alt="image" />
        <div class="feedback-desc">
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis
            ipsum suspendisse.
          </p>
          <div class="rating">
            <i class="bx bxs-star"></i>
            <i class="bx bxs-star"></i>
            <i class="bx bxs-star"></i>
            <i class="bx bxs-star"></i>
            <i class="bx bxs-star"></i>
          </div>
          <div class="client-info">
            <h3>Steven Smith</h3>
            <span>CEO at Envato</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</section> -->

<!-- <section class="pricing-area pt-100 pb-70 bg-f4f5fe">
  <div class="container">
    <div class="section-title">
      <h2>Choose The Pricing Plan</h2>
    </div>
    <div class="tab pricing-list-tab">
      <ul class="tabs">
        <li>
          <a href="#"><i class="bx bxs-calendar-check"></i> Monthly</a>
        </li>
        <li>
          <a href="#"><i class="bx bxs-calendar-check"></i> Yearly</a>
        </li>
      </ul>
      <div class="tab_content">
        <div class="tabs_item">
          <div class="row">
            <div class="col-lg-4 col-sm-6">
              <div class="single-pricing-table left-align">
                <div class="pricing-header">
                  <h3>Free</h3>
                </div>
                <div class="price"><sup>$</sup>0 <sub>/ monthly</sub></div>
                <ul class="pricing-features">
                  <li>
                    <i class="bx bxs-badge-check"></i> Up to 3 chat operators
                    <span
                      class="tooltips bx bxs-info-circle"
                      data-toggle="tooltip"
                      data-placement="right"
                      title="Tight pants next level keffiyeh you probably haven't heard of them."
                    ></span>
                  </li>

                  <li>
                    <i class="bx bxs-badge-check"></i> 100 ChatBot Triggers
                  </li>

                  <li><i class="bx bxs-badge-check"></i> 24/7 Live Chat</li>

                  <li>
                    <i class="bx bxs-badge-check"></i> Email Integration
                    <span
                      class="tooltips bx bxs-info-circle"
                      data-toggle="tooltip"
                      data-placement="right"
                      title="Tight pants next level keffiyeh you probably haven't heard of them."
                    ></span>
                  </li>

                  <li>
                    <i class="bx bxs-badge-check"></i> Messenger Integration
                  </li>

                  <li><i class="bx bxs-badge-check"></i> Visitor Info</li>

                  <li>
                    <i class="bx bxs-badge-check"></i> Mobile + Desktop Apps
                  </li>

                  <li>
                    <i class="bx bxs-badge-check"></i> Quick Responses
                    <span
                      class="tooltips bx bxs-info-circle"
                      data-toggle="tooltip"
                      data-placement="right"
                      title="Tight pants next level keffiyeh you probably haven't heard of them."
                    ></span>
                  </li>

                  <li>
                    <i class="bx bxs-badge-check"></i> Drag & Drop Widgets
                  </li>

                  <li>
                    <i class="bx bxs-badge-check"></i> Visitor Notes
                    <span
                      class="tooltips bx bxs-info-circle"
                      data-toggle="tooltip"
                      data-placement="right"
                      title="Tight pants next level keffiyeh you probably haven't heard of them."
                    ></span>
                  </li>

                  <li><i class="bx bxs-badge-check"></i> Google Analytics</li>
                </ul>
                <div class="btn-box">
                  <a href="#" class="default-btn"
                    ><i class="bx bxs-hot"></i> Try It Free Now<span></span
                  ></a>
                </div>
              </div>
            </div>

            <div class="col-lg-4 col-sm-6">
              <div class="single-pricing-table left-align">
                <div class="pricing-header">
                  <h3>Starter</h3>
                </div>
                <div class="price"><sup>$</sup>49 <sub>/ monthly</sub></div>
                <ul class="pricing-features">
                  <li>
                    <i class="bx bxs-badge-check"></i> Up to 4 chat operators
                    <span
                      class="tooltips bx bxs-info-circle"
                      data-toggle="tooltip"
                      data-placement="right"
                      title="Tight pants next level keffiyeh you probably haven't heard of them."
                    ></span>
                  </li>

                  <li>
                    <i class="bx bxs-badge-check"></i> 150 ChatBot Triggers
                  </li>

                  <li><i class="bx bxs-badge-check"></i> 24/7 Live Chat</li>

                  <li>
                    <i class="bx bxs-badge-check"></i> Email Integration
                    <span
                      class="tooltips bx bxs-info-circle"
                      data-toggle="tooltip"
                      data-placement="right"
                      title="Tight pants next level keffiyeh you probably haven't heard of them."
                    ></span>
                  </li>

                  <li>
                    <i class="bx bxs-badge-check"></i> Messenger Integration
                  </li>

                  <li><i class="bx bxs-badge-check"></i> Visitor Info</li>

                  <li>
                    <i class="bx bxs-badge-check"></i> Mobile + Desktop Apps
                  </li>

                  <li>
                    <i class="bx bxs-badge-check"></i> Quick Responses
                    <span
                      class="tooltips bx bxs-info-circle"
                      data-toggle="tooltip"
                      data-placement="right"
                      title="Tight pants next level keffiyeh you probably haven't heard of them."
                    ></span>
                  </li>

                  <li>
                    <i class="bx bxs-badge-check"></i> Drag & Drop Widgets
                  </li>

                  <li>
                    <i class="bx bxs-badge-check"></i> Visitor Notes
                    <span
                      class="tooltips bx bxs-info-circle"
                      data-toggle="tooltip"
                      data-placement="right"
                      title="Tight pants next level keffiyeh you probably haven't heard of them."
                    ></span>
                  </li>

                  <li><i class="bx bxs-badge-check"></i> Google Analytics</li>
                </ul>
                <div class="btn-box">
                  <a href="#" class="default-btn"
                    ><i class="bx bxs-hot"></i> Try It Free Now<span></span
                  ></a>
                </div>
              </div>
            </div>

            <div class="col-lg-4 col-sm-6 offset-lg-0 offset-sm-3">
              <div class="single-pricing-table left-align">
                <div class="pricing-header">
                  <h3>Professional</h3>
                </div>
                <div class="price"><sup>$</sup>79 <sub>/ monthly</sub></div>
                <ul class="pricing-features">
                  <li>
                    <i class="bx bxs-badge-check"></i> Up to 7 chat operators
                    <span
                      class="tooltips bx bxs-info-circle"
                      data-toggle="tooltip"
                      data-placement="right"
                      title="Tight pants next level keffiyeh you probably haven't heard of them."
                    ></span>
                  </li>

                  <li>
                    <i class="bx bxs-badge-check"></i> 250 ChatBot Triggers
                  </li>

                  <li><i class="bx bxs-badge-check"></i> 24/7 Live Chat</li>

                  <li>
                    <i class="bx bxs-badge-check"></i> Email Integration
                    <span
                      class="tooltips bx bxs-info-circle"
                      data-toggle="tooltip"
                      data-placement="right"
                      title="Tight pants next level keffiyeh you probably haven't heard of them."
                    ></span>
                  </li>

                  <li>
                    <i class="bx bxs-badge-check"></i> Messenger Integration
                  </li>

                  <li><i class="bx bxs-badge-check"></i> Visitor Info</li>

                  <li>
                    <i class="bx bxs-badge-check"></i> Mobile + Desktop Apps
                  </li>

                  <li>
                    <i class="bx bxs-badge-check"></i> Quick Responses
                    <span
                      class="tooltips bx bxs-info-circle"
                      data-toggle="tooltip"
                      data-placement="right"
                      title="Tight pants next level keffiyeh you probably haven't heard of them."
                    ></span>
                  </li>

                  <li>
                    <i class="bx bxs-badge-check"></i> Drag & Drop Widgets
                  </li>

                  <li>
                    <i class="bx bxs-badge-check"></i> Visitor Notes
                    <span
                      class="tooltips bx bxs-info-circle"
                      data-toggle="tooltip"
                      data-placement="right"
                      title="Tight pants next level keffiyeh you probably haven't heard of them."
                    ></span>
                  </li>

                  <li><i class="bx bxs-badge-check"></i> Google Analytics</li>
                </ul>
                <div class="btn-box">
                  <a href="#" class="default-btn"
                    ><i class="bx bxs-hot"></i> Try It Free Now<span></span
                  ></a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="tabs_item">
          <div class="row">
            <div class="col-lg-4 col-md-6">
              <div class="single-pricing-table left-align">
                <div class="pricing-header">
                  <h3>Free</h3>
                </div>
                <div class="price"><sup>$</sup>0 <sub>/ yearly</sub></div>
                <ul class="pricing-features">
                  <li>
                    <i class="bx bxs-badge-check"></i> Up to 3 chat operators
                    <span
                      class="tooltips bx bxs-info-circle"
                      data-toggle="tooltip"
                      data-placement="right"
                      title="Tight pants next level keffiyeh you probably haven't heard of them."
                    ></span>
                  </li>

                  <li>
                    <i class="bx bxs-badge-check"></i> 100 ChatBot Triggers
                  </li>

                  <li><i class="bx bxs-badge-check"></i> 24/7 Live Chat</li>

                  <li>
                    <i class="bx bxs-badge-check"></i> Email Integration
                    <span
                      class="tooltips bx bxs-info-circle"
                      data-toggle="tooltip"
                      data-placement="right"
                      title="Tight pants next level keffiyeh you probably haven't heard of them."
                    ></span>
                  </li>

                  <li>
                    <i class="bx bxs-badge-check"></i> Messenger Integration
                  </li>

                  <li><i class="bx bxs-badge-check"></i> Visitor Info</li>

                  <li>
                    <i class="bx bxs-badge-check"></i> Mobile + Desktop Apps
                  </li>

                  <li>
                    <i class="bx bxs-badge-check"></i> Quick Responses
                    <span
                      class="tooltips bx bxs-info-circle"
                      data-toggle="tooltip"
                      data-placement="right"
                      title="Tight pants next level keffiyeh you probably haven't heard of them."
                    ></span>
                  </li>

                  <li>
                    <i class="bx bxs-badge-check"></i> Drag & Drop Widgets
                  </li>

                  <li>
                    <i class="bx bxs-badge-check"></i> Visitor Notes
                    <span
                      class="tooltips bx bxs-info-circle"
                      data-toggle="tooltip"
                      data-placement="right"
                      title="Tight pants next level keffiyeh you probably haven't heard of them."
                    ></span>
                  </li>

                  <li><i class="bx bxs-badge-check"></i> Google Analytics</li>
                </ul>
                <div class="btn-box">
                  <a href="#" class="default-btn"
                    ><i class="bx bxs-hot"></i> Try It Free Now<span></span
                  ></a>
                </div>
              </div>
            </div>

            <div class="col-lg-4 col-md-6">
              <div class="single-pricing-table left-align">
                <div class="pricing-header">
                  <h3>Starter</h3>
                </div>
                <div class="price"><sup>$</sup>79 <sub>/ yearly</sub></div>
                <ul class="pricing-features">
                  <li>
                    <i class="bx bxs-badge-check"></i> Up to 4 chat operators
                    <span
                      class="tooltips bx bxs-info-circle"
                      data-toggle="tooltip"
                      data-placement="right"
                      title="Tight pants next level keffiyeh you probably haven't heard of them."
                    ></span>
                  </li>

                  <li>
                    <i class="bx bxs-badge-check"></i> 150 ChatBot Triggers
                  </li>

                  <li><i class="bx bxs-badge-check"></i> 24/7 Live Chat</li>

                  <li>
                    <i class="bx bxs-badge-check"></i> Email Integration
                    <span
                      class="tooltips bx bxs-info-circle"
                      data-toggle="tooltip"
                      data-placement="right"
                      title="Tight pants next level keffiyeh you probably haven't heard of them."
                    ></span>
                  </li>

                  <li>
                    <i class="bx bxs-badge-check"></i> Messenger Integration
                  </li>

                  <li><i class="bx bxs-badge-check"></i> Visitor Info</li>

                  <li>
                    <i class="bx bxs-badge-check"></i> Mobile + Desktop Apps
                  </li>

                  <li>
                    <i class="bx bxs-badge-check"></i> Quick Responses
                    <span
                      class="tooltips bx bxs-info-circle"
                      data-toggle="tooltip"
                      data-placement="right"
                      title="Tight pants next level keffiyeh you probably haven't heard of them."
                    ></span>
                  </li>

                  <li>
                    <i class="bx bxs-badge-check"></i> Drag & Drop Widgets
                  </li>

                  <li>
                    <i class="bx bxs-badge-check"></i> Visitor Notes
                    <span
                      class="tooltips bx bxs-info-circle"
                      data-toggle="tooltip"
                      data-placement="right"
                      title="Tight pants next level keffiyeh you probably haven't heard of them."
                    ></span>
                  </li>

                  <li><i class="bx bxs-badge-check"></i> Google Analytics</li>
                </ul>
                <div class="btn-box">
                  <a href="#" class="default-btn"
                    ><i class="bx bxs-hot"></i> Try It Free Now<span></span
                  ></a>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 offset-lg-0 offset-md-3">
              <div class="single-pricing-table left-align">
                <div class="pricing-header">
                  <h3>Professional</h3>
                </div>
                <div class="price"><sup>$</sup>99 <sub>/ yearly</sub></div>
                <ul class="pricing-features">
                  <li>
                    <i class="bx bxs-badge-check"></i> Up to 7 chat operators
                    <span
                      class="tooltips bx bxs-info-circle"
                      data-toggle="tooltip"
                      data-placement="right"
                      title="Tight pants next level keffiyeh you probably haven't heard of them."
                    ></span>
                  </li>

                  <li>
                    <i class="bx bxs-badge-check"></i> 250 ChatBot Triggers
                  </li>

                  <li><i class="bx bxs-badge-check"></i> 24/7 Live Chat</li>

                  <li>
                    <i class="bx bxs-badge-check"></i> Email Integration
                    <span
                      class="tooltips bx bxs-info-circle"
                      data-toggle="tooltip"
                      data-placement="right"
                      title="Tight pants next level keffiyeh you probably haven't heard of them."
                    ></span>
                  </li>

                  <li>
                    <i class="bx bxs-badge-check"></i> Messenger Integration
                  </li>

                  <li><i class="bx bxs-badge-check"></i> Visitor Info</li>

                  <li>
                    <i class="bx bxs-badge-check"></i> Mobile + Desktop Apps
                  </li>

                  <li>
                    <i class="bx bxs-badge-check"></i> Quick Responses
                    <span
                      class="tooltips bx bxs-info-circle"
                      data-toggle="tooltip"
                      data-placement="right"
                      title="Tight pants next level keffiyeh you probably haven't heard of them."
                    ></span>
                  </li>

                  <li>
                    <i class="bx bxs-badge-check"></i> Drag & Drop Widgets
                  </li>

                  <li>
                    <i class="bx bxs-badge-check"></i> Visitor Notes
                    <span
                      class="tooltips bx bxs-info-circle"
                      data-toggle="tooltip"
                      data-placement="right"
                      title="Tight pants next level keffiyeh you probably haven't heard of them."
                    ></span>
                  </li>

                  <li><i class="bx bxs-badge-check"></i> Google Analytics</li>
                </ul>
                <div class="btn-box">
                  <a href="#" class="default-btn"
                    ><i class="bx bxs-hot"></i> Try It Free Now<span></span
                  ></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section> -->

<!-- <section class="faq-area ptb-100">
  <div class="container-fluid">
    <div class="row align-items-center">
      <div class="col-lg-7 col-md-12">
        <div class="faq-accordion">
          <h2>Get to know about <span>Jexsa</span></h2>
          <div
            class="accordion"
            id="accordionEx"
            role="tablist"
            aria-multiselectable="true"
          >
            <div class="card">
              <div class="card-header" role="tab" id="headingOne">
                <a
                  data-toggle="collapse"
                  data-parent="#accordionEx"
                  href="#collapseOne"
                  aria-expanded="false"
                  aria-controls="collapseOne"
                >
                  What access do I have on the free plan?
                  <i class="bx bx-plus"></i>
                </a>
              </div>
              <div
                id="collapseOne"
                class="collapse show"
                role="tabpanel"
                aria-labelledby="headingOne"
                data-parent="#accordionEx"
              >
                <div class="card-body">
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s, when an unknown
                  printer took a galley of type and scrambled it to make a type
                  specimen book.
                </div>
              </div>
            </div>
            <div class="card">
              <div class="card-header" role="tab" id="headingTwo">
                <a
                  class="collapsed"
                  data-toggle="collapse"
                  data-parent="#accordionEx"
                  href="#collapseTwo"
                  aria-expanded="false"
                  aria-controls="collapseTwo"
                >
                  What access do I have on a free trial?
                  <i class="bx bx-plus"></i>
                </a>
              </div>
              <div
                id="collapseTwo"
                class="collapse"
                role="tabpanel"
                aria-labelledby="headingTwo"
                data-parent="#accordionEx"
              >
                <div class="card-body">
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s, when an unknown
                  printer took a galley of type and scrambled it to make a type
                  specimen book.
                </div>
              </div>
            </div>
            <div class="card">
              <div class="card-header" role="tab" id="headingThree">
                <a
                  class="collapsed"
                  data-toggle="collapse"
                  data-parent="#accordionEx"
                  href="#collapseThree"
                  aria-expanded="false"
                  aria-controls="collapseThree"
                >
                  Does the price go up as my team gets larger?
                  <i class="bx bx-plus"></i>
                </a>
              </div>
              <div
                id="collapseThree"
                class="collapse"
                role="tabpanel"
                aria-labelledby="headingThree"
                data-parent="#accordionEx"
              >
                <div class="card-body">
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s, when an unknown
                  printer took a galley of type and scrambled it to make a type
                  specimen book.
                </div>
              </div>
            </div>
            <div class="card">
              <div class="card-header" role="tab" id="headingFour">
                <a
                  class="collapsed"
                  data-toggle="collapse"
                  data-parent="#accordionEx"
                  href="#collapseFour"
                  aria-expanded="false"
                  aria-controls="collapseFour"
                >
                  How can I cancel/pause my subscription?
                  <i class="bx bx-plus"></i>
                </a>
              </div>
              <div
                id="collapseFour"
                class="collapse"
                role="tabpanel"
                aria-labelledby="headingFour"
                data-parent="#accordionEx"
              >
                <div class="card-body">
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s, when an unknown
                  printer took a galley of type and scrambled it to make a type
                  specimen book.
                </div>
              </div>
            </div>
            <div class="card mb-0">
              <div class="card-header" role="tab" id="headingFive">
                <a
                  class="collapsed"
                  data-toggle="collapse"
                  data-parent="#accordionEx"
                  href="#collapseFive"
                  aria-expanded="false"
                  aria-controls="collapseFive"
                >
                  Can I pay via an invoice? <i class="bx bx-plus"></i>
                </a>
              </div>
              <div
                id="collapseFive"
                class="collapse"
                role="tabpanel"
                aria-labelledby="headingFive"
                data-parent="#accordionEx"
              >
                <div class="card-body">
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s, when an unknown
                  printer took a galley of type and scrambled it to make a type
                  specimen book.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-5 col-md-12">
        <div class="faq-image wow fadeInUp" data-wow-delay=".2s">
          <img src="assets/img/faq-img1.png" alt="image" />
        </div>
      </div>
    </div>
  </div>
</section> -->

<!-- <section class="our-loving-clients ptb-100 bg-f4f5fe">
  <div class="container">
    <div class="section-title">
      <h2>Our Loving Clients</h2>
    </div>
    <div class="clients-logo-list align-items-center">
      <div class="single-clients-logo wow fadeInUp" data-wow-delay=".2s">
        <a href="#">
          <img src="assets/img/clients-image/img1.png" alt="image" />
        </a>
      </div>
      <div class="single-clients-logo wow fadeInUp" data-wow-delay=".4s">
        <a href="#">
          <img src="assets/img/clients-image/img2.png" alt="image" />
        </a>
      </div>
      <div class="single-clients-logo wow fadeInUp" data-wow-delay=".6s">
        <a href="#">
          <img src="assets/img/clients-image/img3.png" alt="image" />
        </a>
      </div>
      <div class="single-clients-logo wow fadeInUp" data-wow-delay=".8s">
        <a href="#">
          <img src="assets/img/clients-image/img4.png" alt="image" />
        </a>
      </div>
      <div class="single-clients-logo wow fadeInUp" data-wow-delay="1s">
        <a href="#">
          <img src="assets/img/clients-image/img5.png" alt="image" />
        </a>
      </div>
      <div class="single-clients-logo wow fadeInUp" data-wow-delay="1.2s">
        <a href="#">
          <img src="assets/img/clients-image/img6.png" alt="image" />
        </a>
      </div>
      <div class="single-clients-logo wow fadeInUp" data-wow-delay="1.4s">
        <a href="#">
          <img src="assets/img/clients-image/img7.png" alt="image" />
        </a>
      </div>
      <div class="single-clients-logo wow fadeInUp" data-wow-delay="1.6s">
        <a href="#">
          <img src="assets/img/clients-image/img8.png" alt="image" />
        </a>
      </div>
      <div class="single-clients-logo wow fadeInUp" data-wow-delay="1.8s">
        <a href="#">
          <img src="assets/img/clients-image/img9.png" alt="image" />
        </a>
      </div>
    </div>
  </div>
</section> -->

<!-- <section class="free-trial-area pb-100 bg-f4f5fe">
  <div class="container">
    <div class="free-trial-content">
      <h2>We always try to be the best support to you as possible</h2>
      <p>
        Qualify your leads & recognize the value of word your customer will love
        you
      </p>
      <a routerLink="/contact" class="default-btn"
        ><i class="bx bxs-hot"></i> Contact Us<span></span
      ></a>
    </div>
  </div>
  <div class="shape10">
    <img src="assets/img/shape/shape10.png" alt="image" />
  </div>
  <div class="shape11">
    <img src="assets/img/shape/shape7.png" alt="image" />
  </div>
  <div class="shape12">
    <img src="assets/img/shape/shape11.png" alt="image" />
  </div>
  <div class="shape13">
    <img src="assets/img/shape/shape12.png" alt="image" />
  </div>
</section> -->
