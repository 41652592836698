<!-- <footer class="footer-area"> -->
<!-- <div class="divider"></div>
  <div class="container"> -->
<!-- <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <div class="logo">
                        <a routerLink="/"
                            ><img src="assets/img/b-space-logo.png" alt="image"
                        /></a>
                    </div>
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor incididunt ut labore et dolore
                        magna aliqua. Ut enim ad minim veniam, quis nostrud
                        exercitation ullamco laboris nisi ut aliquip ex ea
                        commodo consequat.
                    </p>
                </div>
            </div>
            <div class="col-lg-2 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <h3>Company</h3>
                    <ul class="services-list">
                        <li><a routerLink="/">About Us</a></li>
                        <li><a routerLink="/">Services</a></li>
                        <li><a routerLink="/">Features</a></li>
                        <li><a routerLink="/">Our Pricing</a></li>
                        <li><a routerLink="/">Latest News</a></li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-2 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <h3>Support</h3>
                    <ul class="support-list">
                        <li><a routerLink="/">FAQ's</a></li>
                        <li><a routerLink="/">Privacy Policy</a></li>
                        <li><a routerLink="/">Terms & Conditions</a></li>
                        <li><a routerLink="/">Community</a></li>
                        <li><a routerLink="/">Contact Us</a></li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <h3>Contact Info</h3>
                    <ul class="footer-contact-info">
                        <li>
                            Location:
                            <a
                                href="https://goo.gl/maps/MQ78iGP5g9VgZcJ38"
                                target="_blank"
                                >6th Floor, Elegant Tower, York, USA</a
                            >
                        </li>
                        <li>
                            Email:
                            <a href="mailto:hello@jexsa.com">hello@jexsa.com</a>
                        </li>
                        <li>
                            Phone: <a href="tel:+321754754">+0 (321) 984 754</a>
                        </li>
                    </ul>
                    <ul class="social">
                        <li>
                            <a href="#" target="_blank"
                                ><i class="bx bxl-facebook"></i
                            ></a>
                        </li>
                        <li>
                            <a href="#" target="_blank"
                                ><i class="bx bxl-twitter"></i
                            ></a>
                        </li>
                        <li>
                            <a href="#" target="_blank"
                                ><i class="bx bxl-linkedin"></i
                            ></a>
                        </li>
                        <li>
                            <a href="#" target="_blank"
                                ><i class="bx bxl-instagram"></i
                            ></a>
                        </li>
                    </ul>
                </div>
            </div>
        </div> -->
<div class="footer-container">
  <p>
    Copyright @2020 B Space.
    <a href="/terms-of-use">Terms of Use</a>&nbsp;
    <a href="/privacy-policy">Privacy Policy</a>
  </p>
  <!-- </div> -->
</div>
<!-- </footer> -->

<div class="go-top"><i class="bx bx-chevron-up"></i></div>
