<div class="page-title-area">
  <div class="container">
    <div class="page-title-content">
      <h2>Services</h2>
      <p>Our Services</p>
    </div>
  </div>
</div>

<section class="features-area pt-100 pb-70 bg-green-white">
  <div class="container">
    <div class="row">
      <div class="col-lg-4 col-md-6">
        <div class="features-box-one">
          <i class="bx bx-conversation bg-13c4a1"></i>
          <h3>IT Consultancy</h3>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor dolore magna.
          </p>
          <a routerLink="/services-details" class="read-more"
            >Read More <i class="bx bx-right-arrow-alt"></i
          ></a>
        </div>
      </div>
      <div class="col-lg-4 col-md-6">
        <div class="features-box-one">
          <i class="bx bx-mobile bg-6610f2"></i>
          <h3>Mobile Applications</h3>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor dolore magna.
          </p>
          <a routerLink="/services-details" class="read-more"
            >Read More <i class="bx bx-right-arrow-alt"></i
          ></a>
        </div>
      </div>
      <div class="col-lg-4 col-md-6">
        <div class="features-box-one">
          <i class="bx bxs-badge-check bg-ffb700"></i>
          <h3>IT Solutions</h3>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor dolore magna.
          </p>
          <a routerLink="/services-details" class="read-more"
            >Read More <i class="bx bx-right-arrow-alt"></i
          ></a>
        </div>
      </div>
      <div class="col-lg-4 col-md-6">
        <div class="features-box-one">
          <i class="bx bx-laptop bg-9c27b0"></i>
          <h3>Web Development</h3>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor dolore magna.
          </p>
          <a routerLink="/services-details" class="read-more"
            >Read More <i class="bx bx-right-arrow-alt"></i
          ></a>
        </div>
      </div>
      <div class="col-lg-4 col-md-6">
        <div class="features-box-one">
          <i class="bx bx-cart bg-3f51b5"></i>
          <h3>eCommerce</h3>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor dolore magna.
          </p>
          <a routerLink="/services-details" class="read-more"
            >Read More <i class="bx bx-right-arrow-alt"></i
          ></a>
        </div>
      </div>

      <div class="col-lg-4 col-md-6">
        <div class="features-box-one">
          <i class="bx bxs-dashboard bg-e91e63"></i>
          <h3>Project Management</h3>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor dolore magna.
          </p>
          <a routerLink="/services-details" class="read-more"
            >Read More <i class="bx bx-right-arrow-alt"></i
          ></a>
        </div>
      </div>
      <div class="col-lg-4 col-md-6">
        <div class="features-box-one">
          <i class="bx bx-data bg-fc3549"></i>
          <h3>Web Hosting</h3>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor dolore magna.
          </p>
          <a routerLink="/services-details" class="read-more"
            >Read More <i class="bx bx-right-arrow-alt"></i
          ></a>
        </div>
      </div>
      <div class="col-lg-4 col-md-6">
        <div class="features-box-one">
          <i class="bx bxs-info-circle bg-00d280"></i>
          <h3>Technical Support</h3>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor dolore magna.
          </p>
          <a routerLink="/services-details" class="read-more"
            >Read More <i class="bx bx-right-arrow-alt"></i
          ></a>
        </div>
      </div>
      <div class="col-lg-4 col-md-6">
        <div class="features-box-one">
          <i class="bx bx-cog bg-ff612f"></i>
          <h3>SEO, Affiliate Marketing</h3>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor dolore magna.
          </p>
          <a routerLink="/services-details" class="read-more"
            >Read More <i class="bx bx-right-arrow-alt"></i
          ></a>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="video-presentation-area ptb-100">
  <div class="container">
    <div class="section-title">
      <h2>Why choose us to watch this video know more</h2>
    </div>
    <div class="video-box">
      <img src="assets/img/video-bg.jpg" class="main-image" alt="image" />
      <a
        href="https://www.youtube.com/watch?v=0gv7OC9L2s8"
        class="video-btn popup-youtube"
        ><i class="bx bx-play"></i
      ></a>
      <div class="shape1">
        <img src="assets/img/shape/shape1.png" alt="image" />
      </div>
      <div class="shape2">
        <img src="assets/img/shape/shape2.png" alt="image" />
      </div>
      <div class="shape3">
        <img src="assets/img/shape/shape3.png" alt="image" />
      </div>
      <div class="shape4">
        <img src="assets/img/shape/shape4.png" alt="image" />
      </div>
      <div class="shape5">
        <img src="assets/img/shape/shape5.png" alt="image" />
      </div>
      <div class="shape6">
        <img src="assets/img/shape/shape6.png" alt="image" />
      </div>
    </div>
    <div class="funfacts-inner">
      <div class="row">
        <div class="col-lg-3 col-6 col-sm-6">
          <div
            class="single-funfacts funfact-style-two wow fadeInLeft"
            data-wow-delay=".2s"
          >
            <i class="bx bx-list-check"></i>
            <h3>
              <span class="odometer" data-count="900">00</span
              ><span class="sign-icon">+</span>
            </h3>
            <p>Completed Projects</p>
          </div>
        </div>
        <div class="col-lg-3 col-6 col-sm-6">
          <div
            class="single-funfacts funfact-style-two wow fadeInLeft"
            data-wow-delay=".4s"
          >
            <i class="bx bx-smile"></i>
            <h3>
              <span class="odometer" data-count="850">00</span
              ><span class="sign-icon">+</span>
            </h3>
            <p>Happy Clients</p>
          </div>
        </div>
        <div class="col-lg-3 col-6 col-sm-6">
          <div
            class="single-funfacts funfact-style-two wow fadeInLeft"
            data-wow-delay=".6s"
          >
            <i class="bx bx-grid-small"></i>
            <h3>
              <span class="odometer" data-count="120">00</span
              ><span class="sign-icon">+</span>
            </h3>
            <p>Ongoing Projects</p>
          </div>
        </div>
        <div
          class="col-lg-3 col-6 col-sm-6 wow fadeInLeft"
          data-wow-delay=".8s"
        >
          <div class="single-funfacts funfact-style-two">
            <i class="bx bxs-award"></i>
            <h3>
              <span class="odometer" data-count="50">00</span
              ><span class="sign-icon">+</span>
            </h3>
            <p>Winning Awards</p>
          </div>
        </div>
      </div>
    </div>
    <div class="contact-cta-box mwidth-1000 wow fadeInUp" data-wow-delay="1s">
      <h3>Have any question about us?</h3>
      <p>Don't hesitate to contact us.</p>
      <a routerLink="/contact" class="default-btn"
        ><i class="bx bxs-edit-alt"></i> Contact Us<span></span
      ></a>
    </div>
  </div>
  <div class="shape-map1"><img src="assets/img/map1.png" alt="image" /></div>
  <div class="shape7">
    <img src="assets/img/shape/shape7.png" alt="image" />
  </div>
  <div class="shape8">
    <img src="assets/img/shape/shape8.png" alt="image" />
  </div>
  <div class="shape9">
    <img src="assets/img/shape/shape9.png" alt="image" />
  </div>
</section>
