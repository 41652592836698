import { Injectable } from '@angular/core'
import { GraphQLClient } from 'graphql-request'
import { of, from } from 'rxjs'
import { HttpClient, HttpHeaders } from '@angular/common/http'

const client = new GraphQLClient(
  'https://fakeql.com/graphql/fec680f41f86609ec872d09b28ef8556',
  {
    headers: {
      Authorization: 'Bearer my-jwt-token'
    }
  }
)

const query = `{
    user (id: 1) {
        id
        firstname
        age
    }
}`

const fakeResponse = () =>
  new Promise((res, rej) => {
    setTimeout(() => {
      // res({
      //   done: 'yes'
      // })
      rej({
        error: true
      })
    }, 2000)
  })
@Injectable({
  providedIn: 'root'
})
export class AppService {
  constructor(private http: HttpClient) {}

  joinPro(form) {
    let headers = new HttpHeaders()
    const formData = new FormData()
    formData.set(
      'xnQsjsdp',
      '480dcacbec6e11ec7ed3674459d155f3a9ff81e23b5440690873665282bb2c21'
    )
    formData.set('zc_gad', '')
    formData.set(
      'xmIwtLD',
      '45b096797b704898594ce03c176668aece44ce9dfc93d0aab02ad3686e23dbf2'
    )
    formData.set('actionType', 'TGVhZHM=')
    formData.set('returnURL', 'https://bspace.ca/')
    formData.set('First Name', form.first)
    formData.set('Last Name', form.last)
    formData.set('Phone', form.tel)
    formData.set('Email', form.email)
    formData.set('Company', form.company)
    formData.set('Description', form.description)
    return this.http.post('https://crm.zoho.com/crm/WebToLeadForm', formData)
  }
}
