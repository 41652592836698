import { Component, OnInit } from '@angular/core'
import { AppService } from 'src/app/app.service'
import { BehaviorSubject } from 'rxjs'

@Component({
  selector: 'app-contact-form',
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.scss']
})
export class ContactFormComponent implements OnInit {
  constructor(private service: AppService) {}
  loading = new BehaviorSubject(false)
  error = new BehaviorSubject(null)
  success = new BehaviorSubject(false)
  submitted = new BehaviorSubject(false)

  contactForm = {
    first: '',
    last: '',
    email: '',
    tel: '',
    company: '',
    description: ''
  }

  submitForm() {
    console.log(this.contactForm)
    this.loading.next(true)
    this.success.next(false)
    this.error.next(false)

    this.service.joinPro(this.contactForm).subscribe(
      () => {
        this.success.next(true)
        this.loading.next(false)
        this.error.next(false)
        this.submitted.next(true)
      },
      (err) => {
        if (err.status !== 200) {
          this.error.next(err)
          this.success.next(false)
          this.loading.next(false)
          this.submitted.next(false)
        } else {
          this.success.next(true)
          this.loading.next(false)
          this.error.next(false)
          this.submitted.next(true)
        }
      }
    )
  }
  ngOnInit() {}
}
