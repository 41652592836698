import { Component, OnInit } from '@angular/core'
import { AppService } from 'src/app/app.service'
import { tap, map, catchError, startWith, finalize } from 'rxjs/operators'
import { BehaviorSubject, of } from 'rxjs'

@Component({
  selector: 'app-home-four',
  templateUrl: './home-four.component.html',
  styleUrls: ['./home-four.component.scss']
})
export class HomeFourComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
