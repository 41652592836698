<div class="page-title-area">
  <div class="container">
    <div class="page-title-content">
      <h2>About Us</h2>
    </div>
  </div>
</div>

<section class="about-area ptb-100">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-6 col-md-12">
        <div class="about-content">
          <!-- <span class="sub-title">How we are Founded</span> -->
          <h3>Our Mission</h3>
          <p>
            B Space takes a revolutionary approach to home design. By combining
            artificial intelligence (AI) technology with state-of-the-art
            rendering capabilities, B Space is ready to offer local renovation
            businesses something they’ve never seen before.
          </p>
          <p>
            Our mission is to serve renovation businesses in North American by
            increasing interior design capabilities and by increasing material
            and labour management efficiency. The B Space platform is a
            cloud-based 3D interior home design platform that connects
            marketing, design, and production. B Space offers AI design with
            high-quality 3D renderings, quick quotations, online purchasing,
            material management and delivery. The 3D rendering software and home
            decor industries will be taken to a new era, with B Space leading
            the way.
          </p>
        </div>
      </div>
      <div class="col-lg-6 col-md-12">
        <div class="about-image">
          <img src="assets/img/about/right1.png" alt="image" />
        </div>
      </div>
    </div>
    <br />
    <div class="row align-items-center">
      <div class="col-lg-6 col-md-12">
        <div class="about-image">
          <img src="assets/img/about/left1.png" alt="image" />
        </div>
      </div>
      <div class="col-lg-6 col-md-12">
        <div class="about-content">
          <!-- <span class="sub-title">How we are Founded</span> -->
          <h3>How We Accomplish Our Mission</h3>
          <p>
            B Space wants renovation businesses to meet market needs and to
            expand their consumer base. The B Space platform will recommend new
            drawing plans and styles that are based on the newest trends in the
            renovating industry. With a click of a button, clients are able
            fully customize their designs by choosing from our extensive library
            of home styles and products.
          </p>
          <p>
            B Space allows businesses and their clients to preview designs with
            an interactive rendering. The B Space platform uses new technology
            that gives high-definition renderings, shareable images and
            shareable demos. The demos have walk-through capabilities and can be
            experienced in full 3D Virtual Reality (VR). 3D VR allows clients to
            immerse themselves in their new home with all of their chosen
            materials.
          </p>
          <p>
            B Space believes in, “what you see is what you get”. The B Space
            platform lets you order everything you see in a rendered design. Our
            quick-ordering feature allows users to order all building,
            renovation, home furnishings and home decorations directly from our
            platform. In addition to shopping, the B Space platform also has
            built-in quotation and intelligent material management features.
            This allows the renovation team to achieve cost control and avoid
            material waste by calculating accurate costs and profits based on
            the materials and products selected. This ensures you know how much
            a project will cost. For your convenience, B Space also delivers to
            you. We eliminate the need for renovation businesses to send their
            employees in-person to purchase building materials.
          </p>
          <p>
            B Space provides new possibilities to the future of the home
            renovation market. B Space aims to empower tens of thousands of home
            renovation businesses by helping them overcome their problems with
            interior design and their problems with high material management and
            labour costs.
          </p>
        </div>
      </div>
    </div>
  </div>
</section>

<app-contact-form></app-contact-form>

<!-- <section class="our-loving-clients ptb-100 bg-f4f5fe">
  <div class="container">
    <div class="section-title">
      <h2>Our Loving Clients</h2>
    </div>
    <div class="clients-logo-list align-items-center">
      <div class="single-clients-logo wow fadeInUp" data-wow-delay=".2s">
        <a href="#">
          <img src="assets/img/clients-image/img1.png" alt="image" />
        </a>
      </div>
      <div class="single-clients-logo wow fadeInUp" data-wow-delay=".4s">
        <a href="#">
          <img src="assets/img/clients-image/img2.png" alt="image" />
        </a>
      </div>
      <div class="single-clients-logo wow fadeInUp" data-wow-delay=".6s">
        <a href="#">
          <img src="assets/img/clients-image/img3.png" alt="image" />
        </a>
      </div>
      <div class="single-clients-logo wow fadeInUp" data-wow-delay=".8s">
        <a href="#">
          <img src="assets/img/clients-image/img4.png" alt="image" />
        </a>
      </div>
      <div class="single-clients-logo wow fadeInUp" data-wow-delay="1s">
        <a href="#">
          <img src="assets/img/clients-image/img5.png" alt="image" />
        </a>
      </div>
      <div class="single-clients-logo wow fadeInUp" data-wow-delay="1.2s">
        <a href="#">
          <img src="assets/img/clients-image/img6.png" alt="image" />
        </a>
      </div>
      <div class="single-clients-logo wow fadeInUp" data-wow-delay="1.4s">
        <a href="#">
          <img src="assets/img/clients-image/img7.png" alt="image" />
        </a>
      </div>
      <div class="single-clients-logo wow fadeInUp" data-wow-delay="1.6s">
        <a href="#">
          <img src="assets/img/clients-image/img8.png" alt="image" />
        </a>
      </div>
      <div class="single-clients-logo wow fadeInUp" data-wow-delay="1.8s">
        <a href="#">
          <img src="assets/img/clients-image/img9.png" alt="image" />
        </a>
      </div>
    </div>
  </div>
</section>

<section class="video-presentation-area ptb-100">
  <div class="container">
    <div class="section-title">
      <h2>Why choose us to watch this video know more</h2>
    </div>
    <div class="video-box">
      <img src="assets/img/video-bg.jpg" class="main-image" alt="image" />
      <a
        href="https://www.youtube.com/watch?v=0gv7OC9L2s8"
        class="video-btn popup-youtube"
        ><i class="bx bx-play"></i
      ></a>
      <div class="shape1">
        <img src="assets/img/shape/shape1.png" alt="image" />
      </div>
      <div class="shape2">
        <img src="assets/img/shape/shape2.png" alt="image" />
      </div>
      <div class="shape3">
        <img src="assets/img/shape/shape3.png" alt="image" />
      </div>
      <div class="shape4">
        <img src="assets/img/shape/shape4.png" alt="image" />
      </div>
      <div class="shape5">
        <img src="assets/img/shape/shape5.png" alt="image" />
      </div>
      <div class="shape6">
        <img src="assets/img/shape/shape6.png" alt="image" />
      </div>
    </div>
    <div class="funfacts-inner">
      <div class="row">
        <div class="col-lg-3 col-6 col-sm-6">
          <div
            class="single-funfacts funfact-style-two wow fadeInLeft"
            data-wow-delay=".2s"
          >
            <i class="bx bx-list-check"></i>
            <h3>
              <span class="odometer" data-count="900">00</span
              ><span class="sign-icon">+</span>
            </h3>
            <p>Completed Projects</p>
          </div>
        </div>
        <div class="col-lg-3 col-6 col-sm-6">
          <div
            class="single-funfacts funfact-style-two wow fadeInLeft"
            data-wow-delay=".4s"
          >
            <i class="bx bx-smile"></i>
            <h3>
              <span class="odometer" data-count="850">00</span
              ><span class="sign-icon">+</span>
            </h3>
            <p>Happy Clients</p>
          </div>
        </div>
        <div class="col-lg-3 col-6 col-sm-6">
          <div
            class="single-funfacts funfact-style-two wow fadeInLeft"
            data-wow-delay=".6s"
          >
            <i class="bx bx-grid-small"></i>
            <h3>
              <span class="odometer" data-count="120">00</span
              ><span class="sign-icon">+</span>
            </h3>
            <p>Ongoing Projects</p>
          </div>
        </div>
        <div
          class="col-lg-3 col-6 col-sm-6 wow fadeInLeft"
          data-wow-delay=".8s"
        >
          <div class="single-funfacts funfact-style-two">
            <i class="bx bxs-award"></i>
            <h3>
              <span class="odometer" data-count="50">00</span
              ><span class="sign-icon">+</span>
            </h3>
            <p>Winning Awards</p>
          </div>
        </div>
      </div>
    </div>
    <div class="contact-cta-box mwidth-1000 wow fadeInUp" data-wow-delay="1s">
      <h3>Have any question about us?</h3>
      <p>Don't hesitate to contact us.</p>
      <a routerLink="/contact" class="default-btn"
        ><i class="bx bxs-edit-alt"></i> Contact Us<span></span
      ></a>
    </div>
  </div>
  <div class="shape-map1"><img src="assets/img/map1.png" alt="image" /></div>
  <div class="shape7">
    <img src="assets/img/shape/shape7.png" alt="image" />
  </div>
  <div class="shape8">
    <img src="assets/img/shape/shape8.png" alt="image" />
  </div>
  <div class="shape9">
    <img src="assets/img/shape/shape9.png" alt="image" />
  </div>
</section>

<section class="team-area pt-100 pb-70">
  <div class="container">
    <div class="section-title">
      <h2>Meet Our experts always ready to help you</h2>
    </div>
    <div class="row">
      <div class="col-lg-3 col-sm-6 col-md-6">
        <div class="single-team-box wow fadeInUp" data-wow-delay=".2s">
          <div class="image">
            <img src="assets/img/team-image/img1.jpg" alt="image" />
            <ul class="social">
              <li>
                <a href="#" target="_blank"><i class="bx bxl-facebook"></i></a>
              </li>
              <li>
                <a href="#" target="_blank"><i class="bx bxl-twitter"></i></a>
              </li>
              <li>
                <a href="#" target="_blank"><i class="bx bxl-linkedin"></i></a>
              </li>
              <li>
                <a href="#" target="_blank"><i class="bx bxl-instagram"></i></a>
              </li>
            </ul>
          </div>
          <div class="content">
            <h3>Alex Maxwel</h3>
            <span>CEO & Founder</span>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-sm-6 col-md-6">
        <div class="single-team-box wow fadeInUp" data-wow-delay=".4s">
          <div class="image">
            <img src="assets/img/team-image/img2.jpg" alt="image" />
            <ul class="social">
              <li>
                <a href="#" target="_blank"><i class="bx bxl-facebook"></i></a>
              </li>
              <li>
                <a href="#" target="_blank"><i class="bx bxl-twitter"></i></a>
              </li>
              <li>
                <a href="#" target="_blank"><i class="bx bxl-linkedin"></i></a>
              </li>
              <li>
                <a href="#" target="_blank"><i class="bx bxl-instagram"></i></a>
              </li>
            </ul>
          </div>
          <div class="content">
            <h3>Sarah Taylor</h3>
            <span>UX/UI Designer</span>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-sm-6 col-md-6">
        <div class="single-team-box wow fadeInUp" data-wow-delay=".6s">
          <div class="image">
            <img src="assets/img/team-image/img3.jpg" alt="image" />
            <ul class="social">
              <li>
                <a href="#" target="_blank"><i class="bx bxl-facebook"></i></a>
              </li>
              <li>
                <a href="#" target="_blank"><i class="bx bxl-twitter"></i></a>
              </li>
              <li>
                <a href="#" target="_blank"><i class="bx bxl-linkedin"></i></a>
              </li>
              <li>
                <a href="#" target="_blank"><i class="bx bxl-instagram"></i></a>
              </li>
            </ul>
          </div>
          <div class="content">
            <h3>Lee Munroe</h3>
            <span>Web Developer</span>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-sm-6 col-md-6">
        <div class="single-team-box wow fadeInUp" data-wow-delay=".8s">
          <div class="image">
            <img src="assets/img/team-image/img4.jpg" alt="image" />
            <ul class="social">
              <li>
                <a href="#" target="_blank"><i class="bx bxl-facebook"></i></a>
              </li>
              <li>
                <a href="#" target="_blank"><i class="bx bxl-twitter"></i></a>
              </li>
              <li>
                <a href="#" target="_blank"><i class="bx bxl-linkedin"></i></a>
              </li>
              <li>
                <a href="#" target="_blank"><i class="bx bxl-instagram"></i></a>
              </li>
            </ul>
          </div>
          <div class="content">
            <h3>Calvin Klein</h3>
            <span>Support</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="app-download-area ptb-100">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-5 col-md-12">
        <div class="app-download-image wow fadeInLeft" data-wow-delay=".3s">
          <img src="assets/img/mobile.png" alt="image" />
        </div>
      </div>
      <div class="col-lg-7 col-md-12">
        <div class="app-download-content">
          <span class="sub-title">Download App</span>
          <h2>Supporting your customers on the go with our mobile app</h2>
          <div class="btn-box">
            <a href="#" class="apple-store-btn"
              ><img src="assets/img/applestore.png" alt="image" />Download on
              the<span>Apple Store</span></a
            >
            <a href="#" class="play-store-btn"
              ><img src="assets/img/playstore.png" alt="image" />Get it on<span
                >Google Play</span
              ></a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="feedback-area pt-100 pb-70">
  <div class="container">
    <div class="section-title">
      <h2>Whats Our Clients Said About <span>Jexsa</span></h2>
    </div>
    <div class="feedback-slides owl-carousel owl-theme">
      <div class="single-feedback-item">
        <img src="assets/img/woman1.png" alt="image" />
        <div class="feedback-desc">
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis
            ipsum suspendisse.
          </p>
          <div class="rating">
            <i class="bx bxs-star"></i>
            <i class="bx bxs-star"></i>
            <i class="bx bxs-star"></i>
            <i class="bx bxs-star"></i>
            <i class="bx bxs-star"></i>
          </div>
          <div class="client-info">
            <h3>Sarah Taylor</h3>
            <span>CEO at Envato</span>
          </div>
        </div>
      </div>
      <div class="single-feedback-item">
        <img src="assets/img/woman2.png" alt="image" />
        <div class="feedback-desc">
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis
            ipsum suspendisse.
          </p>
          <div class="rating">
            <i class="bx bxs-star"></i>
            <i class="bx bxs-star"></i>
            <i class="bx bxs-star"></i>
            <i class="bx bxs-star"></i>
            <i class="bx bxs-star"></i>
          </div>
          <div class="client-info">
            <h3>Olivar Lucy</h3>
            <span>CEO at EnvyTheme.com</span>
          </div>
        </div>
      </div>
      <div class="single-feedback-item">
        <img src="assets/img/man1.png" alt="image" />
        <div class="feedback-desc">
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis
            ipsum suspendisse.
          </p>
          <div class="rating">
            <i class="bx bxs-star"></i>
            <i class="bx bxs-star"></i>
            <i class="bx bxs-star"></i>
            <i class="bx bxs-star"></i>
            <i class="bx bxs-star"></i>
          </div>
          <div class="client-info">
            <h3>Steven Smith</h3>
            <span>CEO at Envato</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="free-trial-area ptb-100 bg-f4f5fe">
  <div class="container">
    <div class="free-trial-content">
      <h2>We always try to be the best support to you as possible</h2>
      <p>
        Qualify your leads & recognize the value of word your customer will love
        you
      </p>
      <a routerLink="/contact" class="default-btn"
        ><i class="bx bxs-hot"></i> Contact Us<span></span
      ></a>
    </div>
  </div>
  <div class="shape10">
    <img src="assets/img/shape/shape10.png" alt="image" />
  </div>
  <div class="shape11">
    <img src="assets/img/shape/shape7.png" alt="image" />
  </div>
  <div class="shape12">
    <img src="assets/img/shape/shape11.png" alt="image" />
  </div>
  <div class="shape13">
    <img src="assets/img/shape/shape12.png" alt="image" />
  </div>
</section> -->
